import React, { useState, useRef, useEffect } from "react";
import { DocumentCategory } from "./types";
import { useDocuments } from "../../Providers/DocumentsProvider";

import { CategorySectionStyles } from "./documentsStyles";
import { DownCarot } from "../../ui/icons";
import SubcategorySection from "./SubcategorySection";

const CategorySection = ({ documentCategory, tabView }: { documentCategory: DocumentCategory; tabView?: boolean }) => {
  const [open, setOpen] = useState(true);
  const { addRefToCategory } = useDocuments();
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    addRefToCategory(documentCategory.scrollToName, ref);
  }, []);

  return (
    <CategorySectionStyles open={open} tabView={tabView}>
      <div ref={ref} className="category-header_wrap">
        <div className="category-header">{documentCategory.name}</div>
        <DownCarot className="section-open-carot" onClick={() => setOpen(!open)} />
      </div>
      <div className="list_wrap">
        {documentCategory.subcategories.map((s) => (
          <SubcategorySection categoryName={documentCategory.name} documentSubcategory={s} key={s.id} />
        ))}
      </div>
    </CategorySectionStyles>
  );
};

export default CategorySection;
