import styled from "@emotion/styled";
import React, { useState } from "react";
import { useDocuments } from "../../../Providers/DocumentsProvider";
import Spinner from "../../../ui/Spinner";
import BasicMerge from "./BasicMerge";
import Copy from "./Copy";
import Delete from "./Delete";
import MergeEmail from "./MergeEmail";
import MergePrint from "./MergePrint";
import AddDocument from "./AddDocument";
import { ButtonGroup } from "../../../ui/ButtonGroup";

export default function SelectedFileActions() {
  const { selectedDocuments, downloadDocumentsAndZipHandler } = useDocuments();
  const [isDownloading, setIsDownloading] = useState(false);

  async function handleDownload() {
    if (!selectedDocuments.length) {
      alert("No documents have been selected");
      return;
    }
    setIsDownloading(true);
    await downloadDocumentsAndZipHandler();
    setIsDownloading(false);
  }

  return (
    <ButtonGroup>
      <MergeEmail />
      <MergePrint />
      <BasicMerge />
      <Copy />
      <Delete />
      <div onClick={handleDownload} className="file-action-button">
        Download
        <Spinner local={true} display={isDownloading} />
      </div>
    </ButtonGroup>
  );
}
