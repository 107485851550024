import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete, Box, Grid, Stack, CircularProgress } from "@mui/material";
import { useOrder } from "../../../Providers/OrderProvider";
import axios from "axios";
import { ContactType, SelectUnderwriter, SelectTitleCompany } from "../../../Providers/types";

interface IProps {
  contactType: ContactType;
  selectedUnderwriter: SelectUnderwriter;
  setSelectedUnderwriter: React.Dispatch<React.SetStateAction<SelectUnderwriter>>;
  selectedTitleCompany: SelectTitleCompany;
  setSelectedTitleCompany: React.Dispatch<React.SetStateAction<SelectTitleCompany>>;
}

export default function UnderwriterOrTitleCompany(props: IProps) {
  const { setSelectedUnderwriter, selectedUnderwriter, selectedTitleCompany, setSelectedTitleCompany, contactType } =
    props;
  const { order } = useOrder();
  const contacts = order?.contacts || [];
  const stateCode = order.stateCode || "";
  const underWriterContact = contacts.find((c) => c.type == "Underwriter");
  const titleAgentContact = contacts.find((c) => c.type == "Title Company" || c.type == "TitleCompany");

  const [titleCompanyOptions, setTitleCompanyOptions] = useState<SelectTitleCompany[]>([]);
  const [underwriterOptions, setUnderwriterOptions] = useState<SelectUnderwriter[]>([]);
  const [isLoadingTitleCompanies, setIsLoadingTitleCompanies] = useState(true);
  const [isLoadingUnderwriters, setIsLoadingUnderwriters] = useState(true);
  const isLoadingTitleCompaniesOrUnderwriters = isLoadingTitleCompanies || isLoadingUnderwriters;

  useEffect(() => {
    return () => {
      setSelectedTitleCompany(null);
      setSelectedUnderwriter(null);
    };
  }, []);

  useEffect(() => {
    if (!stateCode) {
      setIsLoadingTitleCompanies(false);
      return;
    }
    const getTitleCompaniesByState = async () => {
      setIsLoadingTitleCompanies(true);
      const { data } = await axios.get<SelectTitleCompany[]>(`/api/Orders/getTitleCompaniesByState?state=${stateCode}`);
      if (data) {
        setTitleCompanyOptions(data);
        const defaultOption = data.find((titleCompany) => titleCompany.name == titleAgentContact.name);
        if (defaultOption) {
          setSelectedTitleCompany(defaultOption);
        }
      }
      setIsLoadingTitleCompanies(false);
    };
    getTitleCompaniesByState();
  }, [stateCode]);

  useEffect(() => {
    if (!selectedTitleCompany?.name) {
      setIsLoadingUnderwriters(false);
      return;
    }
    const getUnderwritersByTitleCompany = async () => {
      setIsLoadingUnderwriters(true);
      const { data } = await axios.get<SelectUnderwriter[]>(
        `/api/Orders/getUnderwritersByTitleCompany?titleCompany=${selectedTitleCompany.lookupCode}`
      );
      if (data) {
        setUnderwriterOptions(data);
        const defaultOption = data.find((underwriterOption) => underwriterOption.payeeName == underWriterContact.name);
        if (defaultOption) {
          setSelectedUnderwriter(defaultOption);
        }
      }
      setIsLoadingUnderwriters(false);
    };
    getUnderwritersByTitleCompany();
  }, [selectedTitleCompany]);

  return (
    <>
      <Grid container spacing={2} marginTop={1} marginBottom={1}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="center">
            <Box width={"50%"}>
              <Stack spacing={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Contact Type"
                  size="small"
                  value={contactType?.name || ""}
                  disabled
                />
                <Autocomplete
                  disableClearable
                  loading={isLoadingTitleCompanies}
                  disabled //disabled={isLoadingTitleCompanies} /* When we deal with the JV issue we can remove title companies from always being disabled to this instead */
                  multiple={false}
                  id="title-company-options"
                  options={titleCompanyOptions}
                  value={selectedTitleCompany}
                  onChange={(event: any, newValue: SelectTitleCompany | null) => {
                    setSelectedUnderwriter(null);
                    setSelectedTitleCompany(newValue);
                  }}
                  getOptionLabel={(option: SelectTitleCompany) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      label="Title Company"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingTitleCompanies ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />

                <Autocomplete
                  loading={isLoadingTitleCompaniesOrUnderwriters}
                  disabled={isLoadingTitleCompaniesOrUnderwriters}
                  multiple={false}
                  id="underwriter-options"
                  options={underwriterOptions}
                  value={selectedUnderwriter}
                  onChange={(event: any, newValue: SelectUnderwriter | null) => {
                    setSelectedUnderwriter(newValue);
                  }}
                  getOptionLabel={(option: SelectUnderwriter) => option.payeeName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      label="Underwriter"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingTitleCompaniesOrUnderwriters ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
              </Stack>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
