import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import React from "react";
import { useOrder } from "../../../Providers/OrderProvider";
import { ContactType, Contact } from "../../../Providers/types";

export default function ContactTypeDropdown({
  setContactType,
  contactType,
  setContact,
  contact,
  isEdit
}: {
  setContactType: React.Dispatch<React.SetStateAction<ContactType>>;
  contactType: ContactType;
  setContact: React.Dispatch<React.SetStateAction<Contact>>;
  contact: Contact;
  isEdit?: boolean;
}) {
  const { contactTypeList } = useOrder();

  const getContactTypeLabel = (option: any) => {
    if (option.isOther && !option.name) {
      return "Other";
    }
    return option.name || option;
  };

  const filter = createFilterOptions({
    matchFrom: "any",
    stringify: (option: Contact) => {
      return option?.name.replace("'", "");
    }
  });

  return (
    <Autocomplete
      value={(contactType?.name || null || undefined || "") as any}
      options={contactTypeList}
      getOptionLabel={(option) => getContactTypeLabel(option)}
      filterOptions={filter}
      disabled={isEdit}
      autoHighlight
      freeSolo
      size="small"
      onChange={(e, value: any) => {
        setContact((contact) => ({ type: value?.name, contactType: value?.name }) as Contact);
        setContactType(value);
      }}
      renderInput={(params) => (
        <TextField autoFocus={true} size="small" {...params} label="Contact Type" placeholder="Start typing..." />
      )}
    />
  );
}
