import React from "react";
import styled from "@emotion/styled";
import { isDevelopment } from "../../helpers/envChecker";
import Spinner from "../../ui/Spinner";
import { IFramePageViewTypes } from "../../Providers/types";

interface IProps {
  lookupCode?: string;
  page?: IFramePageViewTypes;
  defaultName?: string;
  defaultCompanyType?: string;
}

const getIFrameSource = ({
  page = "view-contact",
  lookupCode = "",
  defaultName = "",
  defaultCompanyType = ""
}: {
  page: IFramePageViewTypes;
  lookupCode: string;
  defaultName: string;
  defaultCompanyType: string;
}) => {
  const domain = isDevelopment() ? "localhost:3000" : "titleq.mcres.com";
  const urlFirstPart = `https://${domain}/phonebook`;

  if (page == "view-contact" && lookupCode) {
    return `${urlFirstPart}/contact/profile?lookupcode=${lookupCode}`;
  }

  if (page == "view-company" && lookupCode) {
    return `${urlFirstPart}/company/profile?lookupcode=${lookupCode}`;
  }
  if (page == "create-company") {
    return `${urlFirstPart}/company/create-company?defaultName=${defaultName}&defaultCompanyType=${defaultCompanyType}`;
  }

  return `${urlFirstPart}/create-contact?defaultname=${defaultName}`;
};

const ClientPhonebookIFrame = ({ lookupCode = "", page, defaultName = "", defaultCompanyType = "" }: IProps) => {
  return (
    <Root>
      <Spinner local={true} display={true} />
      <iframe
        title="Client Phonebook"
        src={getIFrameSource({ page, lookupCode, defaultName, defaultCompanyType })}
        width="1000"
        height="100%"
      />
    </Root>
  );
};

export default ClientPhonebookIFrame;

const Root = styled.div<{ iframeDisplay?: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
  iframe {
    z-index: 265;
    position: relative;
  }
`;
