import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { OrderContextType, ExchangeData, ContactType, Contact, Order } from "./types";
import { OrderNote } from "../types/home/order";

const OrderContext = React.createContext<OrderContextType | null>(null);

interface OrderProviderProps {
  children: React.ReactNode;
}

function OrderProvider({ children }: OrderProviderProps) {
  const { orderNumber } = useParams();
  const [order, setOrder] = useState<Order>();
  const [exchangeData, setExchangeData] = useState<ExchangeData>();
  const [isLoading, setIsLoading] = useState(true);
  const [updatedContacts, setUpdatedContacts] = useState<string[]>([]);
  const [contactTypeList, setContactTypeList] = useState<ContactType[]>([]);

  useEffect(() => {
    async function getExchangeData() {
      const { data } = await axios.get<ExchangeData>(`/api/orders/exchange/${orderNumber}`);
      setExchangeData(data);
    }

    (async () => {
      await getOrderInfo();
      await getOrderContacts();
      if (orderNumber.toLowerCase().startsWith("madex")) {
        await getExchangeData();
      }
    })();
  }, [orderNumber]);
  const getOrderInfo = async () => {
    const { data } = await axios.get<Order>(`/api/orders/getorder/${orderNumber}`);
    setIsLoading(false);
    setOrder(data);
  };

  const getOrderNotes = async ()=>{
    const { data } = await axios.get<OrderNote[]>(`/api/orders/getnotes/${orderNumber}`);
    setOrder((order) => (!!order ? { ...order, notes: [...data] } : null));
  }

  const getOrderContacts = async () => {
    const { data } = await axios.get<Contact[]>(`/api/orders/getcontacts/${orderNumber}`);
    setOrder((order) => (!!order ? { ...order, contacts: [...data] } : null));
  };

  function handleUpdatedContacts(checked: boolean, contactCode: string) {
    const newArray = checked ? [...updatedContacts, contactCode] : updatedContacts.filter((x) => x !== contactCode);
    setUpdatedContacts(newArray);
  }

  const getContactTypeList = async () => {
    const { data } = await axios.get("/api/orders/availablecontacttypes");
    const uniquesMap = {};
    //you need to make the list unique since the data returns a few doubles and then the sort will not work
    data.forEach((d: ContactType) => {
      if (!uniquesMap[d.name]) {
        uniquesMap[d.name] = d;
      }
    });
    setContactTypeList(Object.values(uniquesMap));
  };

  const value: OrderContextType = {
    order,
    exchangeData,
    isLoading,
    getOrderContacts,
    getOrderNotes,
    updatedContacts,
    handleUpdatedContacts,
    getContactTypeList,
    contactTypeList,
    getOrderInfo,
    setOrder
  };

  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>;
}

function useOrder() {
  return useContext(OrderContext);
}

export { OrderProvider, useOrder };
