import React from "react";

import { Root, Avatar, OpenInIcon, EnvelopeIcon, PhoneIcon, LocationIcon, SuitcaseIcon } from "./contactCardStyles";
import { Contact } from "../../../Providers/types";
interface IProps {
  contact: Contact;
  editClick?: () => void;
  selectedCompanyBranch;
  nameOverride: string | null;
  isDisplayEditIcon?: boolean;
}

export default function ContactCard({
  contact,
  editClick,
  selectedCompanyBranch,
  nameOverride,
  isDisplayEditIcon = true
}: IProps) {
  return (
    <Root className="conttcardOverride">
      <div className="card_top">
        <Avatar notAllowed={false} hasContact={contact?.name} />
      </div>
      {contact?.name && isDisplayEditIcon && (
        <div className="edit-icon" onClick={editClick}>
          <OpenInIcon />
        </div>
      )}
      {!contact?.name && !nameOverride && <div className="tac">????</div>}
      <div className="tac">
        <div className="bold fs16">{nameOverride ? nameOverride : contact?.name}</div>
        {contact?.role && <div className="mt1 fs14 semi-bold italic">{contact?.role}</div>}
        {contact?.company && (
          <div className="fs14 mt1 pl1 pr1">
            <span className="dark-gray">at</span> <span className="semi-bold">{contact?.company}</span>
          </div>
        )}
        <div className="contact_info">
          <div className="email-dfv">
            <EnvelopeIcon />
            {!contact?.name ? (
              "--"
            ) : (
              <div>
                {!!contact.email &&
                  (contact.emailsSplit?.length ? contact.emailsSplit : contact?.email.split(";")).map((c) => (
                    <div>{c}</div>
                  ))}
              </div>
            )}
          </div>

          <div>
            <PhoneIcon />
            {!contact?.name ? "--" : <span>{contact?.phone}</span>}
          </div>
          <div>
            <LocationIcon />
            {!contact?.name ? (
              "--"
            ) : (
              <div>
                <div>
                  {contact?.otherContactType ? contact.address1 : selectedCompanyBranch?.address1}
                  <div>{contact?.otherContactType ? contact.address2 : selectedCompanyBranch?.address2}</div>
                </div>
                <div>
                  {contact?.otherContactType ? contact.city : selectedCompanyBranch?.city}
                  {contact?.otherContactType ? contact.city && "," : selectedCompanyBranch?.city && ","}{" "}
                  {contact?.otherContactType ? contact.state : selectedCompanyBranch?.state}{" "}
                  {contact?.otherContactType ? contact.zip : selectedCompanyBranch?.zip}
                </div>
              </div>
            )}
          </div>
          <div>
            <SuitcaseIcon />
            {!contact?.name ? "--" : <span>{contact?.salesRep}</span>}
          </div>
        </div>
      </div>
    </Root>
  );
}
