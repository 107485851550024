import axios from "axios";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import getBase64 from "../../helpers/fileUpload";
import useDocumentDetailsFormState from "../../hooks/use-document-details-form-state";
import { useDocuments } from "../../Providers/DocumentsProvider";
import { BaseButton, ButtonsWrap, GhostButton } from "../../ui/modalButtons";
import Sidedraw from "../../ui/sidedraw/index";
import Spinner from "../../ui/Spinner";
import { InfoContentWrap, InfoHeaderWrap } from "./actionsBarStyles";
import DocumentDetailsForm from "./document-actions/DocumentDetailsForm";
import ImportedFileTag from "./ImportedFileTag";
import { DocumentOptions, DragDrop, DropzoneWrap, UploadButton } from "./uploadStyles";

export function UploadModal() {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState<File>();
  const [fileRequired, setFileRequired] = useState(false);
  const { getDocuments, uncheckAllDocuments, fileToUpload } = useDocuments();

  useEffect(() => {
    if (fileToUpload != null) {
      setShowModal(true);
      setFile(fileToUpload);
    }
  }, [fileToUpload]);

  const closeModal = () => {
    setShowModal(false);
    setFile(null);
    rerender();
  };

  const onSubmitClick = () => {
    setSubmitClicked(true);
    if (file == null) {
      setFileRequired(true);
    }
  };

  const handleSubmit = async () => {
    if (fileRequired) {
      return;
    }
    setIsSubmitting(true);

    let { description, additionalDescription } = data;
    if (description === "Other") {
      description = data.additionalDescription;
    } else if (!!additionalDescription) {
      description += ` ${additionalDescription}`;
    }
    const payload = {
      document: {
        mtaNum: data.mtaNum,
        textToLink: data.textToLink,
        category: data.category,
        subcategoryId: data.subcategoryId,
        documentDescription: description,
        saveToWeb: false
      },
      base64File: await getBase64(file),
      fileName: file.name
    };
    await axios.post("/api/documents/upload", payload);
    await getDocuments();

    setIsSubmitting(false);
    uncheckAllDocuments();
    closeModal();
  };

  const onDrop = async (file: any) => {
    setFile(file[0]);
    setFileRequired(false);
  };

  const onRemoveFileClick = () => {
    setFile(null);
    setFileRequired(true);
  };

  const changeCustomFileName = (newName: string) => {
    const newFile = new File([file], newName);
    setFile(newFile);
  };

  const { data, errors, setData, setSubmitClicked, rerender } = useDocumentDetailsFormState(handleSubmit);

  return (
    <>
      <div onClick={() => setShowModal(true)}>Upload</div>
      {showModal && (
        <Sidedraw isOpen={showModal} close={() => closeModal}>
          <InfoHeaderWrap>
            <h1>Upload</h1>
          </InfoHeaderWrap>

          <InfoContentWrap>
            <DocumentDetailsForm data={data} setData={setData} errors={errors} />
          </InfoContentWrap>

          <DropzoneWrap>
            <Dropzone onDrop={onDrop} multiple={false}>
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <DocumentOptions required={fileRequired}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <UploadButton>Upload Document</UploadButton>
                      <DragDrop>{isDragActive ? <span>drop here</span> : <span>or drag and drop file</span>}</DragDrop>
                    </div>
                  </DocumentOptions>
                );
              }}
            </Dropzone>
            {file != null && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 16,
                  marginTop: 16
                }}
              >
                <ImportedFileTag
                  fileNameToDisplay={file.name}
                  onRemoveClick={onRemoveFileClick}
                  changeCustomFileName={changeCustomFileName}
                />
              </div>
            )}
          </DropzoneWrap>
          <ButtonsWrap>
            <GhostButton onClick={closeModal}>Cancel</GhostButton>
            <BaseButton type="submit" onClick={onSubmitClick}>
              <Spinner local={true} display={isSubmitting} />
              Submit
            </BaseButton>
          </ButtonsWrap>
        </Sidedraw>
      )}
    </>
  );
}
