import React from "react";
import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";

const pulse = keyframes`
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  `;

export const Bones = styled.div`
  background-color: #f7f7f9;
  border-radius: 8px;
  height: 16px;
  width: 100%;
  margin-bottom: 10px;
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: ${pulse} 2s infinite linear;
  :last-of-type {
    margin-bottom: 0px;
    width: 75%;
  }
`;

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  pointer-events: none;
`;

export default function Skeleton({ children }) {
  return <Root>{children}</Root>;
}
