import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { convertToFileDownload } from "../helpers/fileDownload";
import { isMobile } from "../helpers/formatters";
import { Document, DocumentCategory } from "../pages/new-home/types";
import { produce } from "immer";
import {
  DocumentsContextType,
  DocumentsProviderProps,
  EmailSendOptions,
  EndPointMap,
  ModalType,
  possibleEndPointsTypes,
  SendConfirmationBody
} from "./types";

const endPointMap: EndPointMap = {
  confirmationAsLink: "/api/emailgenerator/forconfirmationaslink",
  confirmationAsAttachment: "/api/emailgenerator/forconfirmationasattachment",
  commitmentAsAttachment: "/api/emailgenerator/forcommitmentasattachment",
  commitmentAsLink: "/api/emailgenerator/forcommitmentaslink",
  documentAsAttachment: "/api/emailgenerator/fordocuments",
  documentAsLink: "/api/emailgenerator/fordocumentsaslink"
};

const DocumentsContext = React.createContext<DocumentsContextType>({
  documentsByCategory: [],
  getDocuments: () => {},
  categories: [],
  handleDocumentCheckbox: () => {},
  handleSubCategoryCheckbox: () => {},
  categoryToRef: {},
  addRefToCategory: () => {},
  handleScrollClick: () => {},
  handleSearchInputChange: () => {},
  searchText: "",
  setSearchText: () => {},
  emailSendingHandler: () => {},
  uncheckAllDocuments: () => {},
  checkAllDocuments: () => {},
  selectedDocuments: [],
  downloadDocumentsAndZipHandler: async () => {},
  documentViewMode: "category",
  setDocumentViewMode: () => {},
  currentOpenModal: ModalType.None,
  toggleModal: (modalType: ModalType) => {},
  closeModal: () => {},
  isLoading: true,
  isTabView: false,
  setIsTabView: (value) => {},
  activeTabCategory: "",
  setActiveTabCategory: (category) => {},
  areAllChecked: () => false,
  handleUploadDrop: (files: File[]) => {},
  fileToUpload: null,
  isNotesBarSectionOpen: false,
  setIsNotesBarSectionOpen: () => {},
  isEditBarSectionOpen: false,
  setIsEditBarSectionOpen: () => {}
});

function DocumentsProvider({ children }: DocumentsProviderProps) {
  const { orderNumber } = useParams();
  const [documentsByCategory, setDocumentsByCategory] = React.useState<DocumentCategory[]>([]);
  const [categories, setCategories] = React.useState<DocumentCategory[]>([]);
  const [categoryToRef, setCategoryToRef] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [selectedDocuments, setSelectedDocuments] = React.useState<Document[]>([]);
  const [documentViewMode, setDocumentViewMode] = React.useState<"category" | "date">(isMobile() ? "date" : "category");
  const [currentOpenModal, setCurrentOpenModal] = React.useState(ModalType.None);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isTabView, setIsTabView] = React.useState(false);
  const [activeTabCategory, setActiveTabCategory] = React.useState("");
  const [fileToUpload, setFileToUpload] = React.useState<File>(null);
  const [isNotesBarSectionOpen, setIsNotesBarSectionOpen] = useState(false);
  const [isEditBarSectionOpen, setIsEditBarSectionOpen] = useState(false);

  const filterDocs = () => {
    const filtered: DocumentCategory[] = [];
    documentsByCategory.forEach((category) => {
      const newCategory = { ...category, subcategories: [] };
      category.subcategories.forEach((sub) => {
        const newSubCategory = { ...sub, documents: [] };
        newSubCategory.documents = sub.documents.filter(
          (doc) =>
            doc.documentDescription === null || doc.documentDescription.toLowerCase().includes(searchText.toLowerCase())
        );
        if (newSubCategory.documents.length) {
          newCategory.subcategories.push(newSubCategory);
        }
      });
      if (newCategory.subcategories.length) {
        filtered.push(newCategory);
      }
    });

    return filtered;
  };

  const getDocuments = async () => {
    const { data } = await axios.get(`/api/documents/getall/${orderNumber}`);
    const documents: DocumentCategory[] = data.map((doc: Omit<DocumentCategory, "scrollToName">) => {
      const document: DocumentCategory = {
        name: doc.name,
        scrollToName: doc.name,
        subcategories: doc.subcategories.map((sub) => {
          return {
            id: sub.id,
            name: sub.name,
            documents: sub.documents,
            scrollToName: `${doc.name}${sub.name}`
          };
        })
      };
      return document;
    });
    setDocumentsByCategory(documents);
    setActiveTabCategory(documents.length ? documents[0].name : "");
    getShippingStatusForDocuments(documents).then(() => {});
  };

  const getShippingStatusForDocuments = async (documents: DocumentCategory[]) => {
    const nextState = produce(documents, async (draftState) => {
      const flatDocuments = draftState.flatMap((c) => c.subcategories).flatMap((s) => s.documents);
      const shippingInfo = flatDocuments
        .filter((doc) => doc.trackingNumber)
        .map((doc) => ({
          documentId: doc.id,
          trackingNumber: doc.trackingNumber
        }));
      const { data } = await axios.post("/api/documents/getShippingStatus", shippingInfo);
      flatDocuments.forEach((doc) => {
        const matchingDoc = data.find((d) => d.documentId === doc.id);
        if (matchingDoc) {
          doc.shippingStatus = matchingDoc.shippingStatus;
        }
      });
    });

    // noinspection ES6RedundantAwait
    setDocumentsByCategory(await nextState);
  };

  const getCategoriesAvailableForOrder = async () => {
    const { data } = await axios.get(`/api/documents/categories?orderNumber=${orderNumber}`);
    const categories: DocumentCategory[] = data.map((c) => ({
      name: c.name,
      subcategories: c.subcategories.map((sc) => ({
        name: sc.name,
        id: sc.id,
        descriptions: sc.descriptions?.filter((x) => x)
      }))
    }));
    setCategories(categories);
  };

  React.useEffect(() => {
    const loadAll = async () => {
      await Promise.all([getDocuments(), getCategoriesAvailableForOrder()]);
      setIsLoading(false);
    };
    loadAll();
  }, []);

  function handleSearchInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchText(e.target.value);
  }

  function handleDocumentCheckbox(checked: boolean, document: Document) {
    const newArray = checked ? [...selectedDocuments, document] : selectedDocuments.filter((x) => x !== document);
    setSelectedDocuments(newArray);
  }

  function handleSubCategoryCheckbox(e: React.ChangeEvent<HTMLInputElement>, subcategoryId: string) {
    const subcatDocs = filterDocs()
      .flatMap((d) => d.subcategories)
      .flatMap((d) => d.documents)
      .filter((x) => x.subcategoryId === subcategoryId);

    if (e.target.checked) {
      const docsToSelect = subcatDocs.filter((x) => !selectedDocuments.some((d) => d.id === x.id));
      setSelectedDocuments([...selectedDocuments, ...docsToSelect]);
    } else {
      setSelectedDocuments(selectedDocuments.filter((x) => !subcatDocs.includes(x)));
    }
  }

  function addRefToCategory(scrollToName: string, ref: React.Ref<HTMLDivElement>) {
    setCategoryToRef((current) => {
      const copy = { ...current };
      copy[scrollToName] = ref;
      return copy;
    });
  }

  async function handleScrollClick(scrollToName: string) {
    const element: Element = categoryToRef[scrollToName].current;
    if (!element) {
      return;
    }
    element.scrollIntoView({ behavior: "smooth" });
  }

  async function emailSendingHandler(options: EmailSendOptions, endpointType: possibleEndPointsTypes) {
    const body: SendConfirmationBody = {
      merge: options.merge,
      documentIds: (options.documents || selectedDocuments).map((x) => x.id),
      orderNumber,
      contactEmails: options.contactEmails,
      contactEmailsCc: options.contactEmailsCc,
      firstContact: options.firstContact,
      subject: options.subject,
      titleUrl: options.titleUrl
    };
    const { data } = await axios.post(endPointMap[endpointType], body);
    const fileName = options.subject || `${orderNumber}-email`;
    convertToFileDownload(data.base64Msg, `${fileName}.msg`);
  }

  async function downloadDocumentsAndZipHandler() {
    const documentIds = selectedDocuments.map((x) => x.id);
    const { data } = await axios.post(
      `/api/documents/DownloadAsZip/`,
      { documentIds },
      {
        responseType: "blob"
      }
    );

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Madison-ZippedDocuments.zip`);
    document.body.appendChild(link);
    link.click();

    uncheckAllDocuments();
  }

  const uncheckAllDocuments = () => {
    setSelectedDocuments([]);
  };

  const checkAllDocuments = () => {
    const allDocs = documentsByCategory.flatMap((d) => d.subcategories).flatMap((d) => d.documents);
    setSelectedDocuments(allDocs);
  };

  const areAllChecked = () =>
    documentsByCategory.flatMap((d) => d.subcategories).flatMap((d) => d.documents).length === selectedDocuments.length;

  const toggleModal = (modalType: ModalType) => {
    let desiredType = modalType;
    if (currentOpenModal === modalType) {
      desiredType = ModalType.None;
    }
    setCurrentOpenModal(desiredType);
  };
  const closeModal = () => setCurrentOpenModal(ModalType.None);

  const handleUploadDrop = (files: File[]) => {
    setFileToUpload(files[0]);
  };

  const value: DocumentsContextType = {
    documentsByCategory: filterDocs(),
    getDocuments,
    categories,
    handleDocumentCheckbox,
    handleSubCategoryCheckbox,
    categoryToRef,
    addRefToCategory,
    handleScrollClick,
    handleSearchInputChange,
    searchText,
    setSearchText,
    emailSendingHandler,
    selectedDocuments,
    uncheckAllDocuments,
    checkAllDocuments,
    downloadDocumentsAndZipHandler,
    documentViewMode,
    setDocumentViewMode,
    currentOpenModal,
    toggleModal,
    closeModal,
    isLoading,
    isTabView,
    setIsTabView,
    activeTabCategory,
    setActiveTabCategory,
    areAllChecked,
    handleUploadDrop,
    fileToUpload,
    isNotesBarSectionOpen,
    setIsNotesBarSectionOpen,
    isEditBarSectionOpen,
    setIsEditBarSectionOpen
  };

  return <DocumentsContext.Provider value={value}>{children}</DocumentsContext.Provider>;
}

function useDocuments() {
  return React.useContext(DocumentsContext);
}

export { DocumentsProvider, useDocuments };
