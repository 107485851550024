import Layout from "../layouts/layout";
import React from "react";
import { OrderNotFound } from "./new-home/indexStyles";

const Unauthorized = () => {
  return (
    <Layout>
      <OrderNotFound>This file has been restricted by the legal department. Please contact your manager.</OrderNotFound>
    </Layout>
  );
};

export default Unauthorized;
