import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import HomePage from "./pages/home";
import NewHome from "./pages/new-home/index";
import Orders from "./pages/orders/index";
import Unauthorized from "./pages/Unauthorized";
import { logPageView } from "./helpers/analyticsLogger";

const AppLoadingSpinner = () => {
  const style: any = {
    position: "fixed",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999,
    background: "url('/images/loading-gif2.gif') 50% 50% no-repeat"
  };
  return <div style={style}></div>;
};

const EnsureAuthenticated = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const doLogin = async () => {
      const { data } = await axios.get("/login/isAuthenticated", {
        headers: { Pragma: "no-cache" }
      });
      setIsAuthenticated(data.isAuthenticated);
      if (!data.isAuthenticated) {
        const { pathname, search } = window.location;
        window.location.href = `/login/?returnUrl=${pathname}${search}`;
      }
    };

    doLogin();
  }, []);
  return isAuthenticated ? children : <AppLoadingSpinner />;
};

const RouterLogger = (props) => {
  const location = useLocation();

  useEffect(() => {
    if (window.location.pathname === "/") {
      return; //don't log pageview for root url
    }
    logPageView();
  }, [location]);

  return <>{props.children}</>;
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#141368"
    },
    secondary: {
      main: "#4440DB"
    }
  }
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <EnsureAuthenticated>
        <BrowserRouter>
          <RouterLogger>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/new-home/:orderNumber" component={NewHome} />
              <Route exact path="/orders" component={Orders} />
              <Route exact path="/unauthorized" component={Unauthorized} />
            </Switch>
          </RouterLogger>
        </BrowserRouter>
      </EnsureAuthenticated>
    </ThemeProvider>
  );
};

export default App;
