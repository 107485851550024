/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'; // eslint-disable-line
import styled from '@emotion/styled';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useOrder } from '../../Providers/OrderProvider';
import {
  Calculator,
  CommitmentIcon,
  DocumentsIcon,
  InfoIcon,
  Logo,
  IntelIcon,
  MadexDisbursementSummaryReportIcon,
  MadexDisbursementAuthorizationReportIcon,
  ReadIcon,
  MoneyTransfer,
  ListIconTwo,
} from '../../ui/icons';
import { OrderSearch } from './ActionsBar';
import { NavButton, NavWrap, Root } from './navbarStyles';
import buildIntelUrl from './IntelUrlBuilder';
import { Check } from '@material-ui/icons';

const isMadexOrder = (orderNumber) => orderNumber && orderNumber.toLowerCase().startsWith('madex');

export default function NavBar({ openAppSwitcher = null }) {
  const { orderNumber } = useParams();
  const [isMadexUser, setIsMadexUser] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const checkMadexUser = async () => {
      const { data } = await axios.get('/login/ismadexuser');
      setIsMadexUser(data);
    };
    checkMadexUser();
  }, []);

  let order;
  try {
    //when rendering the navbar on the Order Information page, there's no OrderContext
    //available and this therefore fails. We need the order so that we can properly
    //render the Intel link (we need to get the current order state). Therefore,
    //I'm wrapping this in a try catch so that if there's no OrderContext, we don't
    //blow up and we also won't render the Intel link.
    const orderContext = useOrder();
    order = orderContext.order;
  } catch {}

  const onDisbursementSummaryClick = () => {
    window.open(
      `https://exchangedisbursementsummaryreport.azurewebsites.net/home/index?ordernumber=${orderNumber}&reportType=DisbursementSummary`,
    );
  };

  const onDisbursementAuthorizationClick = () => {
    window.open(
      `https://exchangedisbursementsummaryreport.azurewebsites.net/home/index?ordernumber=${orderNumber}&reportType=DisbursementAuthorization`,
    );
  };
  const isCommercial = order && order.propertyType === 'Commercial';
  return (
    <Root className='navbar-or'>
      <div className='inner-wrap'>
        <a href='/' className='momentum-logo'>
          <Logo />
        </a>
        <span className='momentum-title'>Momentum</span>
        <OrderSearch />
        <NavWrap>
          <NavButton current={true}>
            <DocumentsIcon color='#fff' onClick={() => window.location.reload()} />
            <Tooltip className='show-tooltip'>Documents</Tooltip>
          </NavButton>
          <a href={`/Commitment?orderNumber=${orderNumber}`}>
            <NavButton>
              <CommitmentIcon color='#fff' />
              <Tooltip className='show-tooltip'>Commitment</Tooltip>
            </NavButton>
          </a>
          <a href={`/OrderInfo?orderNumber=${orderNumber}`}>
            <NavButton>
              <InfoIcon color='#fff' />
              <Tooltip className='show-tooltip'>Order Information</Tooltip>
            </NavButton>
          </a>
          <a
            href={`https://operatingtransfers.mcres.com/request/new?order=${orderNumber}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <NavButton>
              <MoneyTransfer color='#fff' />
              <Tooltip className='show-tooltip'>Operating Transfer Request</Tooltip>
            </NavButton>
          </a>
          {order && (
            <a href={buildIntelUrl(order?.stateCode)} target='_blank' rel='noopener noreferrer'>
              <NavButton>
                <IntelIcon color='#fff' />
                <Tooltip className='show-tooltip'>Madison Intel</Tooltip>
              </NavButton>
            </a>
          )}
          {!isMadexOrder(orderNumber) && (
            <a
              href={`https://readers.madisontitle.com/orderService/${orderNumber}/${
                isCommercial ? '1' : '0'
              }`}
              target='_blank'
              rel='noreferrer'
            >
              <NavButton>
                <ReadIcon color='#fff' />
                <Tooltip className='show-tooltip'>Open Reader Tool</Tooltip>
              </NavButton>
            </a>
          )}
          <NavButton onClick={openAppSwitcher}>
            <Calculator color='#fff' />
          </NavButton>
          <div>
            <NavButton onClick={handleClick}>
              <ListIconTwo />
            </NavButton>
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{
                a: {
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  svg: {
                    width: '16px',
                    height: 'auto',
                  }
                }
              }}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleClose} dense={true}>
                <a
                  href={`https://titleq.mcres.com/task-management/closingchecklist?order=${orderNumber}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <Check />
                  Closing Checklist
                </a>
              </MenuItem>
              <MenuItem onClick={handleClose} dense={true}>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href={`https://titleq.mcres.com/recordables/?order=${orderNumber}`}
                >
                  <DocumentsIcon />
                  Recordable Documents
                </a>
              </MenuItem>
            </Menu>
          </div>
          {isMadexOrder(orderNumber) && isMadexUser && (
            <span>
              <NavButton onClick={onDisbursementSummaryClick}>
                <MadexDisbursementSummaryReportIcon color='#fff' />
                <Tooltip className='show-tooltip'>Disbursement Summary Report</Tooltip>
              </NavButton>

              <NavButton onClick={onDisbursementAuthorizationClick}>
                <MadexDisbursementAuthorizationReportIcon color='#fff' />
                <Tooltip className='show-tooltip'>Disbursement Authorization Report</Tooltip>
              </NavButton>
            </span>
          )}
        </NavWrap>
      </div>
    </Root>
  );
}

const Tooltip = styled.div`
  align-items: center;
  background-color: #47517b;
  border-radius: 4px;
  color: #fff;
  display: none;
  font-size: 12px;
  font-weight: bold;
  left: 48px;
  letter-spacing: 0.05em;
  min-height: 32px;
  padding: 0px 8px;
  position: absolute;
  text-transform: uppercase;
  transition: all 0.1s;
  white-space: nowrap;
`;
