import styled from "@emotion/styled";
import React, { useState } from "react";
import { XIcon } from "../../ui/icons";

interface PropTypes {
  fileNameToDisplay: string;
  onRemoveClick: () => void;
  onSaveFileClick?: (e: any) => void;
  filePath?: string;
  changeCustomFileName: (newName: string) => void;
}

const DocumentUploaded = styled.div`
  background-color: #f7f7f9;
  border-radius: 5px;
  display: inline-block;
  height: 40px;
  margin-top: 6px;
  padding: 10px 16px;
  position: relative;
  width: 376px;
`;
const InputStyles = styled.input`
  background-color: #fff;
  border-radius: 5;
  border: 1px solid #ff867f;
  height: 30px;
  left: 10px;
  position: absolute;
  top: 6px;
  width: 278px;
  :focus {
    border: 1px solid #ff867f;
  }
`;

function ImportedFileTag(props: PropTypes) {
  const [editMode, setEditMode] = useState(false);

  return (
    <DocumentUploaded>
      {!editMode ? (
        <div onClick={() => setEditMode(true)}>
          <span>{props.fileNameToDisplay}</span>
          <div onClick={props.onRemoveClick} style={{ position: "absolute", right: 16, top: 8, cursor: "pointer" }}>
            <XIcon />
          </div>
        </div>
      ) : (
        <InputStyles
          autoFocus
          type="text"
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              if (e.target.value !== props.fileNameToDisplay) {
                props.changeCustomFileName(e.target.value);
              }
              setEditMode(false);
            }
          }}
          defaultValue={props.fileNameToDisplay}
          onBlur={(e) => {
            setEditMode(false);
            if (e.target.value !== props.fileNameToDisplay) {
              props.changeCustomFileName(e.target.value);
            }
          }}
        />
      )}
    </DocumentUploaded>
  );
}

export default ImportedFileTag;
