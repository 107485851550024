import React from "react";
import { createTheme } from "@mui/material/styles";
import { Badge, Button, CircularProgress, Link, PaletteColor, ThemeProvider } from "@mui/material";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    customGrey: true;
  }
}

declare module "@mui/material/Badge" {
  interface BadgePropsColorOverrides {
    customGrey: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    customGrey?: PaletteColor;
  }

  interface PaletteOptions {
    customGrey?: PaletteColor;
  }
}

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#1918a9",
      light: "#4f4ee6",
      dark: "#000e7e",
      contrastText: "#ffffff"
    },
    success: {
      main: "#36b37f",
      light: "#6ed6ab",
      dark: "#00804d",
      contrastText: "#ffffff"
    },
    info: {
      main: "#04b8d9",
      light: "#5fdaff",
      dark: "#007c9a",
      contrastText: "#ffffff"
    },
    error: {
      main: "#fa562f",
      light: "#ff8363",
      dark: "#c62400",
      contrastText: "#ffffff"
    },
    customGrey: {
      main: "#e0e5ea",
      light: "#fff",
      dark: "#fff",
      contrastText: "#000"
    }
    // Other palette colors can be modified here (e.g., secondary, warning, etc.)
  }
});

const DashboardButton = ({
  text,
  count,
  color,
  href
}: {
  text: string;
  count: number | undefined;
  color: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  href: string;
}) => {
  const getVariant = () => {
    if (!!!count) {
      return "outlined";
    } else {
      return "contained";
    }
  };

  const isLoading = count === undefined;

  return (
    <ThemeProvider theme={theme}>
      <Link href={href} target="_blank">
        <Badge
          showZero
          badgeContent={count}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          color={(count === 0 ? "customGrey" : "error") as any}
        >
          <Button variant={getVariant()} color={color} size="small" sx={{ paddingRight: 4, paddingLeft: 4 }}>
            {text}
            {isLoading && <CircularProgress size={16} color="inherit" sx={{ position: "absolute", right: 8 }} />}
          </Button>
        </Badge>
      </Link>
    </ThemeProvider>
  );
};

export default DashboardButton;
