import { Global } from "@emotion/react";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import useFitText from "use-fit-text";
import { formatAsCurrency } from "../../helpers/formatters";
import useOnClickOutside from "../../hooks/use-on-outside-click";
import { useOrder } from "../../Providers/OrderProvider";
import {
  Contacts,
  Copy,
  DownCarot,
  GoogleIcon,
  GoogleMapsIcon,
  InfoFileIcon,
  SelectIcon,
  Logo,
  Mail,
  RightArrow,
  ExternalLinkIcon,
  XIcon
} from "../../ui/icons";
import Loading from "../../ui/Loading";
import { OrderSearch } from "./ActionsBar";
import { NotesBar } from "./NotesBar";
import {
  InfoCard,
  InfoCellStyles,
  LoadingCard,
  MobileOrderHeader,
  OrderHeader,
  OrderStatusWrap,
  OuterStatusWrap,
  RelatedOrdersWrap,
  Root,
  Overlay
} from "./orderInfoStyles";

export default function OrderInfo({ vertical, closeSideBar }) {
  const relatedOrdersRef = useRef(null);
  const mobileMenuRef = useRef(null);
  const { order, exchangeData } = useOrder();
  const [propertyIndex, setPropertyIndex] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [showRelatedOrders, setShowRelatedOrders] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showParcels, setShowParcels] = useState(false);
  const [hidePropInfo, setHidePropInfo] = useState(false);

  const isMadexOrder = !!order && order.orderNumber.toLowerCase().startsWith("madex");

  useEffect(() => {
    if (!order) {
      return;
    }
  }, [order]);

  function toggleSelectedProperty(increment) {
    const newIndex = propertyIndex + increment;
    if (newIndex >= 0 && newIndex < order.properties.length) {
      setPropertyIndex(newIndex);
    }
  }
  useOnClickOutside(relatedOrdersRef, () => setShowRelatedOrders(false));
  useOnClickOutside(mobileMenuRef, () => setShowMore(false));
  const getContent = () => {
    if (!order) {
      return (
        <LoadingCard>
          <Loading />
        </LoadingCard>
      );
    }

    const address = (
      <>
        <div className="value">{order.properties[propertyIndex].address}</div>
        {order.properties[propertyIndex].address2 &&
            <div className="value">{order.properties[propertyIndex].address2}</div>
        }
        <div className="value">
          {order.properties[propertyIndex].city}, {order.properties[propertyIndex].state}{" "}
          {order.properties[propertyIndex].zip}
        </div>
      </>
    );
    return (
      <>
        <Root vertical={vertical}>
          <MobileOrderHeader className="mobile-order-header_or">
            <div className="df aic">
              <a href="/" className="momentum-logo">
                <Logo />
              </a>
              <div className="mobile-title-number">{order.titleNumber}</div>
            </div>
            <div className="df aic">
              <Contacts onClick={() => setShowNotes(true)} />
              <OrderSearch />
            </div>
            <NotesBar
              setClose={() => setShowNotes(false)}
              isOpen={showNotes}
              close={() => setShowNotes(false)}
              startWithContacts={true}
            />
            <Global styles={{ ".navbar-or": { display: "none" } }} />
          </MobileOrderHeader>
          {showMore && <Overlay />}
          <InfoCard vertical={vertical} showMore={showMore} hidePropInfo={hidePropInfo}>
            <OrderHeader>
              <div className="mobile-title_wrap">
                <CopyText className="title-number" text={order.titleNumber} textToCopy={order.orderNumber} />
                {order && order.accountRep && (
                  <a
                    href={`mailto:${order?.accountRep.email}?subject=${order?.emailSubjectLine}`}
                    className="account-rep_mobile"
                  >
                    <Mail />
                    <span>{order?.accountRep.fullName}</span>
                  </a>
                )}
                {/* <div className='close-sidebar' onClick={closeSideBar} title='Hide order info side bar'>
                  <LeftArrow />
                </div> */}
                <div className="oijeveoijswr" onClick={() => setShowMore(!showMore)}>
                  <InfoFileIcon />
                </div>
              </div>
              <div className="desktop-sub-info">
                {order.emailSubjectLine && <CopyText className="subject-line" text={order.emailSubjectLine} />}
              </div>
              {order.relatedOrders && (
                <RelatedOrdersWrap showRelatedOrders={showRelatedOrders} ref={relatedOrdersRef}>
                  <div className="pesach" onClick={() => setShowRelatedOrders(!showRelatedOrders)}>
                    Related Orders
                    <DownCarot />
                  </div>
                  <div className="dropdown" onClick={() => setShowRelatedOrders(!showRelatedOrders)}>
                    {order.relatedOrders.split(/,(?![^(]*\))/).map((i, index) => {
                      return (
                        <div>
                          <a
                            key={index}
                            href={`/new-home/${i.trim().split(" ")[0]}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {i.trim()}
                            <ExternalLinkIcon />
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </RelatedOrdersWrap>
              )}
            </OrderHeader>
            <div className="mobile-hide-data" ref={mobileMenuRef}>
              <div className="info-cells_wrap">
                {order.emailSubjectLine && <CopyText className="mobile-subject-line" text={order.emailSubjectLine} />}
                <div className="basic-info">
                  <InfoCell title="Property Type" value={order.propertyType || "--"} />
                  {isMadexOrder && <InfoCell title="Exchange Type" value={order.productType || "--"} />}
                  {!isMadexOrder && <InfoCell title="Transaction Type" value={order.transactionType || "--"} />}
                  {exchangeData && (
                    <>
                      <InfoCell
                        title="45 Day Deadline"
                        value={
                          exchangeData.deadline45Day ? dayjs(exchangeData.deadline45Day)?.format("M/DD/YYYY") : "--"
                        }
                      />
                      <InfoCell
                        title="180 Day Deadline"
                        value={
                          exchangeData.deadline180Day ? dayjs(exchangeData.deadline180Day)?.format("M/DD/YYYY") : "--"
                        }
                      />
                    </>
                  )}
                  <InfoCell
                    title="Commitment Date"
                    value={order.commitmentDate ? dayjs(order.commitmentDate).format("M/DD/YYYY") : "--"}
                  />
                  <OuterStatusWrap>
                    <OrderStatus order={order} />
                  </OuterStatusWrap>
                  {(order.salesPrice || order.salesPrice !== 0) && (
                    <InfoCell title="Purchase Price" value={formatAsCurrency(order.salesPrice) || "--"} />
                  )}
                  {order.loanAmounts && !!order.loanAmounts.length && (
                    <InfoCell
                      title={order.loanAmounts.length > 1 ? "Loan Amounts" : "Loan Amount"}
                      value={order.loanAmounts.map((amount) => formatAsCurrency(amount)).join(" & ") || "--"}
                    />
                  )}
                </div>
                <div className="property-info">
                  <div className="collapse-prop-info_wrap">
                    {hidePropInfo && <div>Property Information</div>}
                    <DownCarot onClick={() => setHidePropInfo(!hidePropInfo)} />
                  </div>
                  {!hidePropInfo && (
                    <div className="order-address_wrap">
                      <InfoCell
                        title="Property Address"
                        value={address}
                        textToCopy={`${order.properties[propertyIndex].address}${order.properties[propertyIndex].address2 ? ` ${order.properties[propertyIndex].address2}` : '' } ${
                          order.properties[propertyIndex].city
                        }, ${order.properties[propertyIndex].state} ${
                          !order.properties[propertyIndex].zip ? "" : order.properties[propertyIndex].zip
                        } `}
                        //make it only copy zip if there is a zip!!
                      />
                      {isMadexOrder && <span>{order.properties[propertyIndex].escrowBriefLegal}</span>}
                    </div>
                  )}
                  {!hidePropInfo && (
                    <>
                      {order.properties[propertyIndex].section && (
                        <InfoCell
                          className="block"
                          title="Section"
                          value={order.properties[propertyIndex].section.toString()}
                        />
                      )}
                      {order.properties[propertyIndex].block && order.properties[propertyIndex].lots[0] ? (
                        <>
                          <InfoCell
                            textToCopy={`Block: ${order.properties[propertyIndex].block}  ${
                              order.properties[propertyIndex].lots.length > 1 ? "Lots:" : "Lot:"
                            } ${order.properties[propertyIndex].lots.join(" & ")}`}
                            className="block"
                            title="Block"
                            value={order.properties[propertyIndex].block}
                          />
                          <InfoCell
                            className="block"
                            // title='Lot'
                            title={order.properties[propertyIndex].lots.length > 1 ? "Lots" : "Lot"}
                            value={
                              order.properties[propertyIndex].lots
                                ? order.properties[propertyIndex].lots.join(" & ")
                                : ""
                            }
                            textToCopy={`Block: ${order.properties[propertyIndex].block}  ${
                              order.properties[propertyIndex].lots.length > 1 ? "Lots:" : "Lot:"
                            }${order.properties[propertyIndex].lots.join(" & ")}`}
                          />
                        </>
                      ) : (
                        <div className="parcels_of_parcel">
                          <InfoCell
                            className="block"
                            title="Parcel Id"
                            value={
                              order.properties[propertyIndex].parcels
                                ? order.properties[propertyIndex].parcels.map((i) => <div>{i}</div>)
                                : ""
                            }
                            textToCopy={` ${order.properties[propertyIndex].parcels}`}
                          />
                        </div>
                      )}

                      <InfoCell className="block" title="County" value={order.properties[propertyIndex].county} />
                      {order.properties[propertyIndex].city !== order.properties[propertyIndex].township && (
                        <InfoCell className="block" title="Township" value={order.properties[propertyIndex].township} />
                      )}
                    </>
                  )}
                </div>
                <div className="address-tools">
                  <div>
                    {order.properties && order.properties.length > 1 && (
                      <div className="pagination-arrows">
                        <RightArrow onClick={() => toggleSelectedProperty(-1)} />
                        <div>
                          Property {order.properties.findIndex((prop) => order.properties[propertyIndex] === prop) + 1}{" "}
                          of {order.properties.length}
                        </div>
                        <RightArrow onClick={() => toggleSelectedProperty(1)} />
                      </div>
                    )}
                  </div>
                  <div className="map-copy">
                    <a href={order ? `sp-select://./ordertracking/order?id=${order.guid}` : ""} title="Open in Select">
                      <SelectIcon />
                    </a>
                    <a
                      href={`https://maps.google.com/?q=${order.properties[propertyIndex].address}, ${order.properties[propertyIndex].city}, ${order.properties[propertyIndex].state}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="search-in-maps"
                      title="Search in Google Maps"
                    >
                      <GoogleMapsIcon />
                    </a>
                    <a
                      href={`http://www.google.com/search?q=${order.properties[propertyIndex].address}, ${order.properties[propertyIndex].city}, ${order.properties[propertyIndex].state}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="search-in-google"
                      title="Search in Google"
                    >
                      <GoogleIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </InfoCard>
        </Root>
      </>
    );
  };

  return getContent();
}

interface Props {
  className?: string;
  text?: string;
  textToCopy?: string;
  fitText?: boolean;
  icon?: boolean;
}

function CopyText({ className, text, textToCopy = null, icon = true }: Props) {
  const [copy, setCopy] = useState(false);
  const [appliedClassName, setAppliedClassName] = useState(className);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy ? textToCopy : text);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  useEffect(() => {
    if (copy) {
      setAppliedClassName(`${className} copied`);
    } else {
      setAppliedClassName(className);
    }
  }, [copy]);

  return (
    <div className={appliedClassName} onClick={copyToClipboard}>
      {icon && <Copy />}
      <span>{text}</span>
    </div>
  );
}

function InfoCell({
  value,
  title,
  className,
  textToCopy
}: {
  value: any;
  title: string;
  className?: string;
  textToCopy?: string;
}) {
  const [copy, setCopy] = useState(false);
  const [appliedClassName, setAppliedClassName] = useState(className);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy ? textToCopy : value);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  useEffect(() => {
    if (copy) {
      setAppliedClassName(`${className} copied`);
    } else {
      setAppliedClassName(className);
    }
  }, [copy]);

  return (
    <InfoCellStyles className={appliedClassName}>
      <div className="title">{title}</div>
      <div className="value" onClick={copyToClipboard}>
        {value || "--"}
      </div>
    </InfoCellStyles>
  );
}

function OrderStatus({ order }) {
  const isMadexOrder = order.orderNumber.replace("-", "").toLowerCase().includes("madex");
  const formattedDate = order.settlementDate ? dayjs(order.settlementDate).format("M/DD/YYYY") : "--";

  switch (order.status) {
    case "Canceled":
    case "Hold":
    case "Transferred":
    case "Duplicate":
      return (
        <OrderStatusWrap>
          <div className={order.status}>{order.status}</div>
        </OrderStatusWrap>
      );
    case "Closed":
    case "Completed":
      return (
        <>
          <InfoCell className="closed-completed" title={`${order.status} on`} value={formattedDate} />
          {isMadexOrder && (
            <OrderStatusWrap>
              <div className={order.status}>{order.status}</div>
            </OrderStatusWrap>
          )}
        </>
      );
    case "In process":
      return (
        <>
          <InfoCell title="Est. closing date" value={formattedDate} />
          {isMadexOrder && (
            <OrderStatusWrap>
              <div className={order.status}>In Process</div>
            </OrderStatusWrap>
          )}
        </>
      );
    case "Firm":
      return <InfoCell title="Scheduled to close" value={formattedDate} />;
    case "Pending":
      return (
        <>
          {isMadexOrder && (
            <OrderStatusWrap>
              <div className={order.status}>Pending</div>
            </OrderStatusWrap>
          )}
        </>
      );
    case "Adjourned":
      return <InfoCell className="adjourned-status" title="Adjourned from" value={formattedDate} />;
    case "Tentative":
      return <InfoCell title="Tentative Closing date" value={formattedDate} />;
    default:
      return <InfoCell title="Settlement date" value={formattedDate} />;
  }
}
