import axios from "axios";

import { startOfToday, addDays } from "date-fns/esm";
import React, { useEffect, useState } from "react";
import { AutoSizer, List } from "react-virtualized";
import { MenuItem, Button } from "@material-ui/core";
import "react-datepicker/dist/react-datepicker.css";
import { Autocomplete, TextField } from "@mui/material";
import "react-virtualized/styles.css";
import DatePicker from "react-datepicker";

import NavBar from "../new-home/NavBar";
import { Root, SortArrow } from "./indexStyles";
import OrderRow from "./OrderRow";
import { XIcon, RightArrow, DownArrow } from "../../ui/icons";
import useDebounce from "../../hooks/use-debounce";
import { startOfTomorrow } from "date-fns";

interface LegacyOrder {
  orderNumber: string;
  titleCode: string;
  titleNumber: string;
  orderDate: Date;
  address: string;
  cityStateZip: string;
  buyer: string;
  client: string;
  closed?: any;
  commitment: string;
  orderStatus: string;
  orderStatusDisplay: string;
  transactionType: string;
  createdBy: string;
}
const statesArrayDefault = [
  "",
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY"
];
const statusArrayDefault = ["", "Canceled", "New", "Commitment", "Closed", "Purchase", "Refinance", "Other"];
export default function Orders() {
  const [orders, setOrders] = useState<LegacyOrder[]>([]);

  const [isClicked, setIsClicked] = useState<boolean>();
  const [columnTurn, setColumnTurn] = useState<string>();
  const [orderSearchText, setOrderSearchText] = useState<string>(null);
  const [statusText, setStatusText] = useState<string>();
  const [stateText, setStateText] = useState<string>();
  const [clientText, setClientText] = useState<string>(null);
  const [buyerText, setBuyerText] = useState<string>(null);
  const [startDate, setStartDate] = useState<Date>(startOfToday());
  const [endDate, setEndDate] = useState<Date>();
  const [createdBySearchText, setCreatedBySearchText] = useState<string>();
  const debouncedOrderSearch = useDebounce(orderSearchText, 500);
  const debouncedStatusText = useDebounce(statusText, 500);
  const debouncedStateText = useDebounce(stateText, 500);
  const debouncedBuyerText = useDebounce(buyerText, 500);
  const debouncedClientText = useDebounce(clientText, 500);
  const debouncedCreatedBySearchText = useDebounce(createdBySearchText, 500);
  const [statesArray, setStatesArray] = useState<string[]>(statesArrayDefault);
  const [statusArray, setStatusArray] = useState<string[]>(statusArrayDefault);
  const requestParams = {
    params: {
      order: debouncedOrderSearch,
      state: debouncedStateText,
      status: debouncedStatusText,
      buyer: debouncedBuyerText,
      client: debouncedClientText,
      fromDate: startDate,
      toDate: endDate || addDays(startDate, 1),
      createdBy: debouncedCreatedBySearchText
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    getOrders();
  }, [
    debouncedOrderSearch,
    debouncedStateText,
    debouncedStatusText,
    debouncedBuyerText,
    debouncedClientText,
    debouncedCreatedBySearchText,
    endDate,
    startDate
  ]);

  const getOrders = async () => {
    const { data } = await axios.get(`/api/OrderInfoPage/searchbyorder`, requestParams);

    setOrders(data);
  };

  const sortData = (sortBy) => {
    isClicked
      ? setOrders([...orders].sort((a, b) => (a[sortBy] > b[sortBy] ? -1 : 1)))
      : setOrders([...orders].sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1)));
    setIsClicked(!isClicked);
  };

  const renderRow = ({ index, key, style }) => {
    const order = orders[index];
    return (
      <div style={style} key={key} className="order-row_wrap">
        <OrderRow key={order.orderNumber} order={order} />
      </div>
    );
  };

  const datePicker = () => {
    const onChange = (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    };

    return (
      <DatePicker
        renderCustomHeader={({ monthDate, customHeaderCount, decreaseMonth, increaseMonth }) => (
          <div>
            <button
              aria-label="Previous Month"
              className={"react-datepicker__navigation react-datepicker__navigation--previous"}
              style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
              onClick={decreaseMonth}
            >
              <span className={"react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"}>
                {"<"}
              </span>
            </button>
            <span className="react-datepicker__current-month">
              {monthDate.toLocaleString("en-US", {
                month: "long",
                year: "numeric"
              })}
            </span>
            <button
              aria-label="Next Month"
              className={"react-datepicker__navigation react-datepicker__navigation--next"}
              style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
              onClick={increaseMonth}
            >
              <span className={"react-datepicker__navigation-icon react-datepicker__navigation-icon--next"}>{">"}</span>
            </button>
          </div>
        )}
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        monthsShown={2}
        selectsRange
        shouldCloseOnSelect={true}
      />
    );
  };

  const clearSearchBars = () => {
    window.location.reload();
  };

  return (
    <Root>
      <NavBar />
      <div className="page-header">
        <div>
          <span style={{ color: "#4440db" }}>{orders.length} </span>
          Orders Found
        </div>
        <div onClick={clearSearchBars} className="button-styles_clear">
          clear all searches
        </div>
      </div>

      <div className="df aic jcc">
        <div className="orders-list_wrap">
          <div className="list-header_wrap">
            <div className="df aic">
              <span>Order Number</span>
              <SortArrow flip={isClicked && columnTurn === "titlenumber"}>
                <DownArrow
                  onClick={() => {
                    sortData("titleNumber");
                    setColumnTurn("titlenumber");
                  }}
                />
              </SortArrow>
            </div>
            <div className="df aic">
              <div>Client</div>
              <SortArrow flip={isClicked && columnTurn === "client"}>
                <DownArrow
                  onClick={() => {
                    sortData("client");
                    setColumnTurn("client");
                  }}
                />
              </SortArrow>
            </div>
            <div className="df aic">
              <div>Buyer</div>
              <SortArrow flip={isClicked && columnTurn === "buyer"}>
                <DownArrow
                  onClick={() => {
                    sortData("buyer");
                    setColumnTurn("buyer");
                  }}
                />
              </SortArrow>
            </div>
            <div className="df aic">
              <div>Address</div>
              <SortArrow flip={isClicked && columnTurn === "address"}>
                <DownArrow
                  onClick={() => {
                    sortData("address");
                    setColumnTurn("address");
                  }}
                />
              </SortArrow>
            </div>
            <div className="df aic">
              <div>Status</div>
              <SortArrow flip={isClicked && columnTurn === "orderStatusDisplay"}>
                <DownArrow
                  onClick={() => {
                    sortData("orderStatusDisplay");
                    setColumnTurn("orderStatusDisplay");
                  }}
                />
              </SortArrow>
            </div>

            <div className="df aic">
              <div>Date</div>
              <SortArrow flip={isClicked && columnTurn === "orderdate"}>
                <DownArrow
                  onClick={() => {
                    sortData("orderdate");
                    setColumnTurn("orderdate");
                  }}
                />
              </SortArrow>
            </div>
            <div className="df aic">
              <div>Created By</div>
              <SortArrow flip={isClicked && columnTurn === "createdBy"}>
                <DownArrow
                  onClick={() => {
                    sortData("createdBy");
                    setColumnTurn("createdBy");
                  }}
                />
              </SortArrow>
            </div>
          </div>

          <div className="list-header_wrap list-header_wrap-filter">
            <div className="df aic">
              <TextField
                id="filled-basic"
                placeholder="Search"
                variant="outlined"
                size="small"
                className="textFieldStyles"
                value={orderSearchText}
                onChange={(e) => setOrderSearchText(e.target.value === "" ? null : e.target.value)}
              />
              {orderSearchText && (
                <XIcon
                  onClick={() => setOrderSearchText("")}
                  style={{
                    width: 13,
                    marginLeft: 10
                  }}
                />
              )}
            </div>
            <div className="df aic">
              <TextField
                id="filled-basic"
                placeholder="Search"
                variant="outlined"
                size="small"
                className="textFieldStyles"
                value={clientText}
                onChange={(e) => setClientText(e.target.value === "" ? null : e.target.value)}
              />
              {clientText && (
                <XIcon
                  onClick={() => setClientText("")}
                  style={{
                    width: 13,
                    marginLeft: 10
                  }}
                />
              )}
            </div>
            <div className="df aic">
              <TextField
                id="filled-basic"
                placeholder="Search"
                variant="outlined"
                size="small"
                className="textFieldStyles"
                value={buyerText}
                onChange={(e) => setBuyerText(e.target.value === "" ? null : e.target.value)}
              />
              {buyerText && (
                <XIcon
                  onClick={() => setBuyerText("")}
                  style={{
                    width: 13,
                    marginLeft: 10
                  }}
                />
              )}
            </div>
            <div>
              <Autocomplete
                value={statesArray.length > 1 ? null : statesArray}
                options={statesArray}
                getOptionLabel={(option) => option?.toString()}
                size="small"
                onChange={(e, value: any) => {
                  if (value === null) {
                    setStatesArray(statesArrayDefault);
                    setStateText("");
                  } else {
                    setStatesArray([value]);
                    setStateText(value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    style={{ maxWidth: "130px", backgroundColor: "#fff" }}
                    size="small"
                    {...params}
                    label="States"
                    placeholder="Start typing..."
                    onChange={(e) => setStateText(e.target.value === "" ? null : e.target.value)}
                  />
                )}
              />
            </div>
            <div>
              <Autocomplete
                value={statusArray.length > 1 ? null : statusArray}
                options={statusArray}
                style={{
                  borderRadius: 4,
                  width: "140px"
                }}
                size="small"
                getOptionLabel={(option) => option?.toString()}
                onChange={(e, value: any) => {
                  if (value === null) {
                    setStatusArray(statusArrayDefault);
                    setStatusText("");
                  } else {
                    setStatusArray([value]);
                    setStatusText(value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    placeholder="Start typing..."
                    size="small"
                    style={{
                      backgroundColor: "#fff",
                      maxWidth: "140px"
                    }}
                    onChange={(e) => setStatusText(e.target.value === "" ? null : e.target.value)}
                  />
                )}
              />
            </div>
            <div className="df aic">
              <div>{datePicker()}</div>
              <XIcon
                onClick={() => {
                  setStartDate(startOfToday());
                  setEndDate(startOfTomorrow());
                }}
                style={{
                  width: "10px",
                  height: "10px",
                  marginLeft: "10px"
                }}
              />
            </div>
            <div className="df aic">
              <TextField
                id="filled-basic"
                placeholder="Search"
                variant="outlined"
                size="small"
                className="textFieldStyles"
                value={createdBySearchText}
                onChange={(e) => setCreatedBySearchText(e.target.value === "" ? null : e.target.value)}
              />
              {createdBySearchText && (
                <XIcon
                  onClick={() => setCreatedBySearchText("")}
                  style={{
                    width: 13,
                    marginLeft: 10
                  }}
                />
              )}
            </div>
          </div>

          <AutoSizer>
            {({ height, width }) => {
              return (
                <List width={width} height={height} rowHeight={68} rowRenderer={renderRow} rowCount={orders.length} />
              );
            }}
          </AutoSizer>
        </div>
      </div>
    </Root>
  );
}
