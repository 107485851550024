import styled from "@emotion/styled";
import { VerticalBaseButton } from "../../../src/ui/modalButtons";
import Skeleton, { Bones } from "../../ui/Skeleton";

type LaytoutProps = {
  vertical?: boolean;
  open?: boolean;
  tabView?: boolean;
  hideSide?: boolean;
};

const rowGrid = "14px 12fr 1fr 6fr 5fr 5fr";
const rowGridDesktop = "14px 12fr 2fr 4fr 100px 6fr";
// const rowGridDesktop = '20px 50px 12fr 4fr 100px 6fr';

export const MainSectionWrap = styled.div<LaytoutProps>`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 1240px) {
    margin-left: ${(props) => (props.hideSide ? "16px" : "320px")};
    transition: all 0.2s;
    width: ${(props) => (props.hideSide ? "calc(100% - 32px)" : "calc(100% - 330px)")};
  }
`;

export const DocumentsStyles = styled.div<LaytoutProps>`
  color: #1f2b5e;
  margin-top: 4px;
  overflow: auto;
  width: 100%;
  position: relative;
  .full-list_wrap {
    height: calc(100vh - 160px);
    overflow-y: auto;
    position: relative;
    padding-bottom: 20px;
  }
  .tab-list_wrap {
    margin-top: 8px;
    max-height: calc(100vh - 192px);
    overflow-y: auto;
  }
  @media (min-width: 900px) {
    border-radius: 4px;
    height: auto;
    margin-top: ${(props) => (props.tabView ? "0" : "16px")};
    padding-right: 8px;
    padding-left: 8px;
    .full-list_wrap {
      height: calc(100vh - 132px);
      scroll-padding-top: 70px;
    }
  }
  @media (min-width: 1240px) {
    padding-right: 0;
    padding-left: 0;
  }
  .transition {
    transition: all 5s;
  }
`;
export const ScrollToTop = styled(VerticalBaseButton)<{ show: boolean }>`
  position: fixed;
  bottom: 26px;
  right: 16px;
  margin: 0px;
  z-index: ${(props) => (props.show ? "999" : "0")};
  transition: all 0.15s;
  opacity: ${(props) => (props.show ? "1" : "0")};
  @media (min-width: 768px) {
    right: 36px;
  }
`;

export const CategorySectionStyles = styled.div<LaytoutProps>`
  background-color: #fff;
  margin-top: ${(props) => (props.tabView ? "8px" : "16px")};
  position: relative;
  transition: all 0.2s;
  border-radius: 4px;
  :first-of-type {
    margin-top: 0;
  }
  .list_wrap {
    height: ${(props) => (props.open ? "auto" : "0px")};
    overflow-y: ${(props) => (props.open ? "auto" : "hidden")};
    padding: 0 8px;
    border: 2px solid #1f2b5e;
    border-top: none;
    border-radius: 0 0 5px 5px;
  }
  .category-header_wrap {
    align-items: center;
    background-color: #1f2b5e;
    color: #fff;
    display: flex;
    height: 40px;
    justify-content: space-between;
    padding: 0 16px;
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 6;
    display: ${(props) => props.tabView && "none"};
  }
  .category-header {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.05em;
  }
  .section-open-carot {
    cursor: pointer;
    max-width: 16px;
    transform: ${(props) => props.open && "rotate(-180deg)"};
    transition: all 0.15s;
    path {
      transition: all 0.15s;
      stroke: #fff;
    }
  }
  @media (max-width: 768px) {
    .list_wrap {
      border-radius: 0px;
      border: none;
    }
  }
  @media (min-width: 900px) {
    border-radius: 7px;
    .category-header_wrap {
      height: 40px;
      border-radius: ${(props) => (props.open ? "4px 4px 0 0" : "4px")};
    }
    .category-header {
      font-size: 18px;
    }
  }
`;

export const SubcategorySectionStyles = styled.div`
  background-color: #ebecf1;
  border-radius: 4px;
  margin-top: 8px;
  border: 1px solid #5b6596;
  overflow: hidden;
  .subcat_wrap {
    align-items: center;
    display: flex;
    font-size: 13px;
    height: 24px;
    padding: 0 8px;
    position: sticky;
    top: 0px;
    > div,
    .subcat_checkbox {
      display: none;
    }
    .subcatheader {
      display: block;
    }
    .actions-column-header {
      justify-self: flex-end;
    }
  }
  .subcatheader {
    font-size: 15px;
    font-weight: 600;
  }
  :last-of-type {
    margin-bottom: 8px;
  }
  @media (min-width: 768px) {
    border-color: #ebecf1;
  }
  @media (min-width: 900px) {
    margin-top: 20px;
    :first-of-type {
      margin-top: 10px;
    }
    .subcat_wrap {
      display: grid;
      grid-column-gap: 16px;
      grid-template-columns: ${rowGrid};
      padding: 0 16px;
      height: 32px;
      > div,
      .subcat_checkbox {
        display: block;
      }
      .subcatheader {
        font-size: 16px;
        margin-left: 0;
      }
    }
  }
  @media (min-width: 1440px) {
    .subcat_wrap {
      grid-template-columns: ${rowGridDesktop};
    }
  }
`;

export const Toast = styled.div<{ show?: boolean }>`
  align-items: center;
  background-color: rgba(71, 81, 123, 1);
  border-radius: 4px;
  bottom: ${(props) => (props.show ? "10px" : "-110px")};
  color: #fff;
  display: flex;
  justify-content: space-between;
  left: 8px;
  max-width: 440px;
  min-height: 44px;
  padding: 8px 8px 8px 16px;
  position: fixed;
  transition: all 0.15s;
  width: calc(100% - 16px);
  z-index: 1000;
  .toast-message {
    font-size: 14px;
    font-weight: 900;
    margin-left: 16px;
  }
  .button-wrap {
    display: flex;
    align-items: center;
    margin-left: 24px;
    button {
      height: 32px;
      background-color: #1f2b5e;
      border-radius: 2px;
      padding: 0 10px;
      color: #fff;
      cursor: pointer;
      :first-of-type {
        background-color: #fff;
        color: #000e48;
        margin-right: 8px;
      }
    }
  }
  @media (min-width: 768px) {
    display: flex;
    left: 50%;
    transform: translateX(-50%);
    bottom: ${(props) => (props.show ? "24px" : "-110px")};
  }
`;

export const DragOverlay = styled.div<{ show?: boolean }>`
  background-color: rgba(71, 81, 123, 0.5);
  bottom: 0;
  left: 8px;
  position: fixed;
  right: 0;
  top: 8px;
  z-index: 99;
  display: ${(props) => (props.show ? "flex" : "none")};
  width: calc(100vw - 16px);
  height: calc(100vh - 16px);
  border: 3px dashed #1f2b5e;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  .drag-overlay-message {
    background-color: #fff;
    border-radius: 4px;
    color: #000e48;
    font-size: 16px;
    font-weight: 900;
    padding: 16px 32px;
    text-align: center;
    min-height: 120px;
    display: flex;
    align-items: center;
  }
`;

export const SkeletonWrap = styled.div`
  background-color: #fff;
  border-radius: 4px;
  margin-top: 0px;
  pointer-events: none;
  margin-bottom: 24px;
  padding: 24px;
  overflow: hidden;
  > div {
    overflow: hidden;
    height: calc(100vh - 196px);
    background-color: #fff;
    /* margin: 24px 0px; */
    > div {
      height: 28px;
      margin-bottom: 16px;
      :nth-of-type(4n + 1) {
        width: 90%;
      }
      :nth-of-type(4n + 2) {
        width: 80%;
      }
      :nth-of-type(4n + 3) {
        width: 84%;
      }
      :nth-of-type(4n + 4) {
        width: 70%;
      }
    }
  }
`;

export const TabsWrap = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  margin-top: 16px;
  padding: 4px;
  position: relative;
  width: auto;
  > div {
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    font-size: 12px;
    font-weight: bold;
    height: 40px;
    margin-right: 8px;
    padding: 0 10px;
    text-transform: uppercase;
    transition: all 0.1s;
    transition:
      opacity 0.1s ease,
      background-color 0.1s ease,
      color 0.1s ease,
      box-shadow 0.1s ease,
      background 0.1s ease,
      -webkit-box-shadow 0.1s ease;
    text-align: center;
    :hover {
      background-color: #ebecf1;
      color: #1f2b5e;
    }
    :last-of-type {
      margin-right: 0;
    }
  }
  .active-tab {
    background-color: #1f2b5e;
    color: #fff;
    cursor: default;
    :hover {
      background-color: #1f2b5e;
      color: #fff;
    }
  }
`;

export const DocumentsNotFound = styled.h1`
  left: 0;
  line-height: 1.3;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  top: 40%;
  font-size: 40px;
  width: 100%;
  @media (min-width: 900px) {
    font-size: 50px;
  }
`;
