import { TransactingParty } from "../pages/new-home/contacts/BuyerSellerForm";
import { Document, DocumentCategory } from "../pages/new-home/types";
import { OrderNote } from "../types/home/order";

export type possibleEndPointsTypes =
  | "confirmationAsLink"
  | "confirmationAsAttachment"
  | "commitmentAsLink"
  | "commitmentAsAttachment"
  | "documentAsAttachment"
  | "documentAsLink";

export type EndPointMap = {
  [key in possibleEndPointsTypes]: string;
};

export interface CategoryToRef {
  [key: string]: React.Ref<HTMLDivElement>;
}

export interface SendConfirmationBody {
  merge: boolean;
  documents?: Document[];
  subject: string;
  firstContact: {
    name: string;
    lookupCode: string;
  };
  titleUrl: string;
  contactEmails: string[];
  contactEmailsCc?: string[];
  documentIds: number[];
  orderNumber: string;
}

export type EmailSendOptions = Omit<SendConfirmationBody, "documentIds" | "orderNumber">;

export interface DocumentsContextType {
  documentsByCategory: Array<DocumentCategory>;
  getDocuments: () => void;
  categories: DocumentCategory[];
  handleDocumentCheckbox: (checked: boolean, document: Document) => void;
  handleSubCategoryCheckbox: (e: React.ChangeEvent<HTMLInputElement>, subcategory: string) => void;
  categoryToRef: CategoryToRef;
  addRefToCategory: (scrollToName: string, ref: React.Ref<HTMLElement>) => void;
  handleScrollClick: (scrollToName: string) => void;
  handleSearchInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchText: string;
  setSearchText: (value: string) => void;
  emailSendingHandler: (options: EmailSendOptions, endpointType: possibleEndPointsTypes) => void;
  uncheckAllDocuments: () => void;
  checkAllDocuments: () => void;
  selectedDocuments: Document[];
  downloadDocumentsAndZipHandler: () => Promise<void>;
  documentViewMode: "category" | "date";
  setDocumentViewMode: (mode: "category" | "date") => void;
  currentOpenModal: ModalType;
  toggleModal: (modalType: ModalType) => void;
  closeModal: () => void;
  isLoading: boolean;
  isTabView: boolean;
  setIsTabView: (value: boolean) => void;
  activeTabCategory: string;
  setActiveTabCategory: (category: string) => void;
  areAllChecked: () => boolean;
  handleUploadDrop: (files: File[]) => void;
  fileToUpload: File;
  isNotesBarSectionOpen: boolean;
  setIsNotesBarSectionOpen: (value: boolean) => void;
  isEditBarSectionOpen: boolean;
  setIsEditBarSectionOpen: (value: boolean) => void;
}

export interface DocumentsProviderProps {
  children: React.ReactNode;
}

export enum ModalType {
  None,
  MergeEmail,
  SendEmail,
  MergePrint,
  AddDocument,
  Merge,
  Copy,
  Delete,
  EmailConfirmationCommitment
}

export interface Contact {
  id: number;
  address1: string;
  address2: string;
  cityStateZip: string;
  city: string;
  state: string;
  zip: string;
  code: string;
  email: string;
  emailsSplit: string[];
  fax: string;
  isGroup: boolean;
  isMarketingSource: boolean;
  lookupCode: string;
  name: string;
  notes: string;
  payeeName: string;
  phone: string;
  cell: string;
  referenceNumber: string;
  represents: string;
  type: string;
  url: string;
  role: string;
  company: string;
  salesRep: string;
  otherContactType: string;
  isNew?: boolean;
  contactType: string;
  licenseNumber?: string | null;
  isIndividual?: boolean;
  branchId?: number;
  buyerSellerInfo?: TransactingParty;
  emailPolicyTo: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
}

export type Note = {
  id: number;
  contactId: number;
  text: string;
  createdAt: string;
  updatedAt: string;
  authorName: string;
  authorUsername: string;
  isClientInstructions: boolean;
};

export type Role = {
  id: number;
  name: string;
};

export type ContactRole = {
  id: number;
  contactId: number;
  companyBranchId: number;
  roleId: number | null;
  businessEmail: string | null;
  phoneNumbers: Phone[];
  branch: CompanyBranch | null;
  role: Role | null;
};

export type Phone = {
  number: string;
  extension: string;
  countryCode: string;
  type: string;
  key: string;
};

export type CompanyType = {
  id: number;
  name: string;
};

export type Company = {
  name: string;
  companyTypeId: number | null;
  id: number;
  dynamicsGuid: string;
  branches: CompanyBranch[];
  notes: Note[];
  isNewCompanyEntry: boolean;
  website: string;
  tempLookupCodesFromImport: string;
  contactRoles: ContactRole[];
  companyType: CompanyType;
  email: string;
  phone: string;
  phoneCountryCode: string;
  fax: string;
  faxCountryCode: string;
  isActive: boolean;
  lookupCode: string;
};

export interface CompanyBranch {
  id: number;
  companyId: number;
  isHeadquarters: boolean;
  email: string;
  phone: string;
  phoneCountryCode: string;
  countryCode: string;
  phoneExtension: string;
  fax: string;
  faxCountryCode: string;
  faxExtension: string;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  country: string | null;
  website: string;
  company: Company;
  isNewCompanyEntry: boolean;
  isNewBranchWithoutCompany: boolean;
  isNewBranchEntry: boolean;
  isReplacementBranch: boolean;
}

export interface Property {
  id: number;
  address: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  township: string;
  block: string;
  escrowBriefLegal: string;
  lots: string[];
  section: string;
  parcels: string[];
}
export type PropertyInfo = {
  id: string | null;
  entries: number | null;
  owner: string | null;
  formattedAddress: string | null;
  address: string | null;
  addressHigh: string | null;
  addressLow: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
  block: string | null;
  lots: string[];
  parcelIds: string[];
  section: string | null;
  borough: string | null;
  aptNo: string | null;
  condoNumber: string | null;
  currentTaxClass: string | null;
  buildingClass: string | null;
  county: string | null | undefined;
  propertyUse: string | null | undefined;
  propertyType: string | null;
  legalDescription: LegalDescription;
};
interface LegalDescription {
  description: string | null;
  lookupCode: string | null;
}

export interface Rep {
  fullName: string;
  displayName: string;
  name: string;
  description: string;
  id: string;
}

export interface Order {
  orderNumber: string;
  titleNumber: string;
  transactionType: string;
  productType: string;
  propertyType: string;
  salesPrice: number;
  loanAmounts: number[];
  commitmentDate: Date;
  settlementDate: Date;
  emailSubjectLine: string;
  stateCode: string;
  status: string;
  guid: string;
  accountRep: {
    email: string;
    fullName: string;
  };
  properties: Property[];
  contacts: Contact[];
  notes: OrderNote[];
  relatedOrders: string;
  dealOwner: string;
}
export interface ExchangeData {
  deadline45Day: Date;
  deadline180Day: Date;
}
export interface ContactType {
  name: string;
  hasLookupApi: boolean;
  isOther: boolean;
  isPhonebookContact: boolean;
}
export type IFramePageViewTypes = "view-contact" | "view-company" | "create-contact" | "create-company";

export type SelectTitleCompany = {
  lookupCode: string;
  name: string;
  accountNumber: string;
  city: string;
};

export type SelectUnderwriter = {
  payeeName: string;
  underwriterLookupCode: string;
  lookupCode: string;
};

export interface IGovernmentContact {
  lookupCode: string;
  name: string;
  payeeName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  fax: string;
}

export interface OrderContextType {
  order: Order;
  exchangeData: ExchangeData;
  isLoading: boolean;
  getOrderContacts: () => void;
  getOrderNotes: () => void;
  updatedContacts: string[];
  handleUpdatedContacts: (checked: boolean, contactCode: string) => void;
  getContactTypeList: () => void;
  contactTypeList: ContactType[];
  getOrderInfo: () => void;
  setOrder: React.Dispatch<React.SetStateAction<Order>>;
}
