import * as React from "react";

export function CalendarIcon({ ...props }: any) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20 5H19V2H17V7H16V5H9V2H7V7H6V5H4C2.897 5 2 5.898 2 7V20C2 21.103 2.897 22 4 22H20C21.103 22 22 21.103 22 20V7C22 5.898 21.103 5 20 5ZM19.997 20H4V9H20L19.997 20Z"
        fill="black"
      />
    </svg>
  );
}
export function BarcodeIcon({ ...props }: any) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2 4H4V20H2V4Z" fill="black" />
      <path d="M6 4H8V16H6V4Z" fill="black" />
      <path d="M9 4H11V16H9V4Z" fill="black" />
      <path d="M13 4H15V16H13V4Z" fill="black" />
      <path d="M17 4H19V16H17V4Z" fill="black" />
      <path d="M20 4H22V20H20V4Z" fill="black" />
      <path d="M6 18H8V20H6V18Z" fill="black" />
      <path d="M9 18H11V20H9V18Z" fill="black" />
      <path d="M13 18H15V20H13V18Z" fill="black" />
      <path d="M17 18H19V20H17V18Z" fill="black" />
    </svg>
  );
}
export function BusinessGraphIcon({ ...props }: any) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22 22V9C22 8.448 21.553 8 21 8H17C16.447 8 16 8.448 16 9V22H15V14.999C15 14.448 14.553 13.999 14 13.999H10C9.447 13.999 9 14.448 9 14.999V22H8V12.999C8 12.448 7.553 11.999 7 11.999H3C2.447 11.999 2 12.448 2 12.999V22H0V24H24V22H22ZM18 10H20V21.999H18V10ZM11 15.999H13V21.999H11V15.999ZM4 13.999H6V21.999H4V13.999Z"
        fill="black"
      />
    </svg>
  );
}

export function FlipIcon({ ...props }: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48" {...props}>
      <circle
        cx="24"
        cy="24"
        r="21.5"
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m26.499 19.394l-1.13 9.43"
        strokeWidth={2}
      />
      <path
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m21.779 14.586l-1.493 12.452c-.128 1.068.49 1.78 1.547 1.78h.528"
        strokeWidth={2}
      />
      <path
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M29.428 25.262c-.234 1.957 1.158 3.558 3.094 3.558s3.712-1.601 3.947-3.558l.277-2.313c.235-1.957-1.158-3.558-3.094-3.558s-3.712 1.6-3.947 3.558m.43-3.556l-1.706 14.232m-16.395-4.801l1.407-11.742c.171-1.424 1.355-2.491 2.763-2.491c1.232 0 1.894.356 2.336 1.067m-7.312 3.736h4.929"
      />
      <circle cx="27.011" cy="15.125" r=".747" fill="black" />
    </svg>
  );
}

export function FilesIcon({ ...props }: any) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.414 0H10C8.897 0 8 0.898 8 2V16C8 17.103 8.897 18 10 18H20C21.103 18 22 17.103 22 16V4.586L17.414 0ZM20.001 16H10V2H16V6H20L20.001 16Z"
        fill="black"
      />
      <path d="M7 3H5V19C5 20.103 5.897 21 7 21H19V19H7V3Z" fill="black" />
      <path d="M4 6H2V22C2 23.103 2.897 24 4 24H16V22H4V6Z" fill="black" />
    </svg>
  );
}
export function CalculatorIcon({ ...props }: any) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17 2H7C5.897 2 5 2.898 5 4V20C5 21.103 5.897 22 7 22H17C18.104 22 19 21.103 19 20V4C19 2.898 18.104 2 17 2ZM17 4L16.999 8H7V4H17ZM7 20V10H16.999L16.997 20H7Z"
        fill="black"
      />
      <path
        d="M9.70711 11.2929C10.0976 11.6834 10.0976 12.3166 9.70711 12.7071C9.31659 13.0976 8.68342 13.0976 8.2929 12.7071C7.90238 12.3166 7.90238 11.6834 8.2929 11.2929C8.68342 10.9024 9.31659 10.9024 9.70711 11.2929Z"
        fill="black"
      />
      <path
        d="M12.7071 11.2929C13.0976 11.6834 13.0976 12.3166 12.7071 12.7071C12.3166 13.0976 11.6834 13.0976 11.2929 12.7071C10.9024 12.3166 10.9024 11.6834 11.2929 11.2929C11.6834 10.9024 12.3166 10.9024 12.7071 11.2929Z"
        fill="black"
      />
      <path
        d="M15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071C15.3166 13.0976 14.6834 13.0976 14.2929 12.7071C13.9024 12.3166 13.9024 11.6834 14.2929 11.2929C14.6834 10.9024 15.3166 10.9024 15.7071 11.2929Z"
        fill="black"
      />
      <path d="M14 5H16V7H14V5Z" fill="black" />
      <path
        d="M9.70711 14.2929C10.0976 14.6834 10.0976 15.3166 9.70711 15.7071C9.31659 16.0976 8.68342 16.0976 8.2929 15.7071C7.90238 15.3166 7.90238 14.6834 8.2929 14.2929C8.68342 13.9024 9.31659 13.9024 9.70711 14.2929Z"
        fill="black"
      />
      <path
        d="M12.7071 14.2929C13.0976 14.6834 13.0976 15.3166 12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071C10.9024 15.3166 10.9024 14.6834 11.2929 14.2929C11.6834 13.9024 12.3166 13.9024 12.7071 14.2929Z"
        fill="black"
      />
      <path
        d="M15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071C13.9024 15.3166 13.9024 14.6834 14.2929 14.2929C14.6834 13.9024 15.3166 13.9024 15.7071 14.2929Z"
        fill="black"
      />
      <path
        d="M9.70711 17.2929C10.0976 17.6834 10.0976 18.3166 9.70711 18.7071C9.31659 19.0976 8.68342 19.0976 8.2929 18.7071C7.90238 18.3166 7.90238 17.6834 8.2929 17.2929C8.68342 16.9024 9.31659 16.9024 9.70711 17.2929Z"
        fill="black"
      />
      <path
        d="M12.7071 17.2929C13.0976 17.6834 13.0976 18.3166 12.7071 18.7071C12.3166 19.0976 11.6834 19.0976 11.2929 18.7071C10.9024 18.3166 10.9024 17.6834 11.2929 17.2929C11.6834 16.9024 12.3166 16.9024 12.7071 17.2929Z"
        fill="black"
      />
      <path
        d="M15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071C13.9024 18.3166 13.9024 17.6834 14.2929 17.2929C14.6834 16.9024 15.3166 16.9024 15.7071 17.2929Z"
        fill="black"
      />
    </svg>
  );
}
export function BankingBillIcon({ ...props }: any) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.5 8H11V6H9V5H7V6.05C5.86 6.283 5 7.292 5 8.5C5 9.878 6.121 11 7.5 11H8.5C8.775 11 9 11.224 9 11.5C9 11.776 8.775 12 8.5 12H5V14H7V15H9V13.95C10.14 13.718 11 12.708 11 11.5C11 10.122 9.879 9 8.5 9H7.5C7.225 9 7 8.776 7 8.5C7 8.224 7.225 8 7.5 8Z"
        fill="black"
      />
      <path
        d="M16 15V1C16 0.653 15.82 0.332 15.525 0.15C15.23 -0.033 14.862 -0.049 14.552 0.105L11.072 1.847L8.555 0.168C8.219 -0.056 7.782 -0.056 7.446 0.168L4.928 1.847L1.448 0.105C1.138 -0.049 0.77 -0.033 0.475 0.15C0.18 0.332 0 0.653 0 1V19C0 19.347 0.18 19.668 0.475 19.852C0.635 19.95 0.817 20 1 20C1.152 20 1.307 19.965 1.447 19.894L4.927 18.154L7.445 19.832C7.781 20.056 8.218 20.056 8.554 19.832L11.071 18.154L13 19.119V16.882L11.447 16.105C11.125 15.945 10.742 15.969 10.445 16.168L8 17.798L5.555 16.168C5.388 16.057 5.194 16 5 16C4.848 16 4.693 16.035 4.553 16.105L2 17.382V2.619L4.553 3.893C4.873 4.054 5.256 4.03 5.555 3.831L8 2.202L10.445 3.832C10.743 4.031 11.127 4.055 11.447 3.894L14 2.619V15H16Z"
        fill="black"
      />
      <path
        d="M22.293 16.294L18 20.586L15.707 18.294L14.293 19.708L18 23.414L23.707 17.708L22.293 16.294Z"
        fill="black"
      />
    </svg>
  );
}
export function InterfaceIcon({ ...props }: any) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 22C6.486 22 2 17.515 2 12C2 6.487 6.486 2 12 2C17.514 2 22 6.486 22 12C22 17.515 17.514 22 12 22ZM12 4C7.589 4 4 7.589 4 12C4 16.411 7.589 20 12 20C16.411 20 20 16.411 20 12C20 7.589 16.411 4 12 4Z"
        fill="black"
      />
      <path d="M13 15V11C13 10.448 12.553 10 12 10H10V12H11V15H9V17H15V15H13Z" fill="black" />
      <path
        d="M12.8839 7.11612C13.3721 7.60427 13.3721 8.39573 12.8839 8.88389C12.3957 9.37204 11.6043 9.37204 11.1161 8.88389C10.628 8.39573 10.628 7.60428 11.1161 7.11612C11.6043 6.62796 12.3957 6.62796 12.8839 7.11612Z"
        fill="black"
      />
    </svg>
  );
}

export function MomentumIcon({ ...props }) {
  return (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.161 16c0-.828-.374-1.595-1.029-2.104L9.637 7.288a2.725 2.725 0 00-2.809-.29 2.682 2.682 0 00-1.495 2.395v13.214c0 1.01.587 1.95 1.495 2.396.923.45 2.008.333 2.81-.29l8.495-6.609A2.65 2.65 0 0019.161 16zM8 22.607V9.393L16.496 16 8 22.607z"
        fill="#000"
      />
      <path
        d="M27.5 14.96L14.167 4.293 12.5 6.375 24.532 16 12.5 25.627l1.665 2.082L27.5 17.043a1.334 1.334 0 00.001-2.083z"
        fill="#000"
      />
    </svg>
  );
}

export function LinkIcon({ ...props }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.192 8.45096L20.484 7.74396L19.07 9.15796L19.778 9.86496C19.967 10.054 20.07 10.304 20.07 10.572C20.07 10.838 19.966 11.089 19.778 11.278L11.293 19.764C10.915 20.141 10.256 20.141 9.878 19.764L9.171 19.057L7.757 20.47L8.464 21.177C9.031 21.744 9.784 22.056 10.586 22.056C11.387 22.056 12.14 21.744 12.707 21.177L21.192 12.692C21.758 12.126 22.07 11.373 22.07 10.572C22.07 9.77096 21.758 9.01696 21.192 8.45096V8.45096Z"
        fill="black"
      />
      <path d="M19.7772 2.79297L21.1914 4.20717L14.1204 11.2782L12.7062 9.86397L19.7772 2.79297Z" fill="black" />
      <path d="M9.87773 12.691L11.2914 14.1043L4.22072 21.177L2.80702 19.7637L9.87773 12.691Z" fill="black" />
      <path
        d="M3.514 16.227L4.928 14.813L4.221 14.106C3.831 13.716 3.831 13.082 4.221 12.691L12.707 4.206C12.895 4.017 13.146 3.913 13.414 3.913C13.682 3.913 13.932 4.017 14.122 4.206L14.829 4.913L16.242 3.5L15.536 2.793C14.402 1.659 12.425 1.659 11.293 2.793L2.808 11.277C1.638 12.447 1.638 14.35 2.808 15.52L3.514 16.227V16.227Z"
        fill="black"
      />
    </svg>
  );
}

export function ListIcon() {
  return (
    <svg
      style={{ maxWidth: 30, position: "relative", left: -4 }}
      height="100%"
      viewBox="0 0 21 21"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd" transform="translate(4 5)">
        <g stroke="#000" strokeLinecap="round" strokeLinejoin="round">
          <path d="m11.5 5.5h-7" />
          <path d="m11.5 9.5h-7" />
          <path d="m11.5 1.5h-7" />
        </g>
        <path
          d="m1.49884033 2.5c.5 0 1-.5 1-1s-.5-1-1-1-.99884033.5-.99884033 1 .49884033 1 .99884033 1zm0 4c.5 0 1-.5 1-1s-.5-1-1-1-.99884033.5-.99884033 1 .49884033 1 .99884033 1zm0 4c.5 0 1-.5 1-1s-.5-1-1-1-.99884033.5-.99884033 1 .49884033 1 .99884033 1z"
          fill="#2a2e3b"
        />
      </g>
    </svg>
  );
}

export function WiredIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M21.5367 20.5812H2.45436C1.92714 20.5812 1.5 21.0083 1.5 21.536C1.5 22.0628 1.92714 22.4899 2.45436 22.4899H21.5362C22.0635 22.4899 22.4906 22.0628 22.4906 21.536C22.4902 21.0083 22.063 20.5812 21.5367 20.5812Z"
        fill="#000"
      />
      <path
        d="M3.64772 18.1001C3.1205 18.1001 2.69336 18.5273 2.69336 19.0545C2.69336 19.5817 3.1205 20.0093 3.64772 20.0093H20.3446C20.8718 20.0093 21.2989 19.5817 21.2989 19.0545C21.2989 18.5273 20.8718 18.1001 20.3446 18.1001H20.1064V9.51266H20.3446C20.6086 9.51266 20.8213 9.29909 20.8213 9.03592C20.8213 8.77276 20.6077 8.55919 20.3446 8.55919H3.64772C3.38411 8.55919 3.17099 8.77276 3.17099 9.03592C3.17099 9.29909 3.38456 9.51266 3.64772 9.51266H3.88631V18.0997H3.64772V18.1001ZM18.1977 9.51266V18.0997H15.3355V9.51266H18.1977ZM13.4268 9.51266V18.0997H10.5646V9.51266H13.4268ZM5.79414 9.51266H8.65633V18.0997H5.79414V9.51266Z"
        fill="#000"
      />
      <path
        d="M2.45438 7.70134H21.5363C21.5394 7.70134 21.543 7.70134 21.5456 7.70134C22.0733 7.70134 22.5 7.2742 22.5 6.74698C22.5 6.32788 22.2301 5.97268 21.8553 5.844L12.3876 1.58377C12.1387 1.47208 11.8541 1.47208 11.6048 1.58377L2.06298 5.87706C1.65238 6.06204 1.42674 6.50794 1.52146 6.94759C1.61574 7.38724 2.00445 7.70134 2.45438 7.70134Z"
        fill="#000"
      />
    </svg>
  );
}
