import React, { useState, useCallback } from "react";
import {
  TextField,
  Autocomplete,
  SxProps,
  AutocompleteRenderOptionState,
  Theme,
  FormControl,
  InputAdornment,
  AutocompleteRenderInputParams,
  CircularProgress
} from "@mui/material";
import { debounce } from "lodash";
import { Icon } from "@iconify/react";
import { Typography } from "@material-ui/core";
interface DebouncedAutoCompleteProps<TOption> {
  value: TOption | null | string;
  options: TOption[];
  getOptionsLabel: (option: TOption) => string;
  renderOption?:
    | ((
        props: React.HTMLAttributes<HTMLLIElement>,
        option: TOption,
        state: AutocompleteRenderOptionState
      ) => React.ReactNode)
    | undefined;
  onSelectedOptionChanged: (e: React.SyntheticEvent<Element, Event>, option: TOption | null | string) => void;
  textboxLabel: string;
  textboxPlaceholder: string;
  onDebouncedTextChanged: (text: string) => Promise<void>;
  required?: boolean | undefined;
  error?: boolean | undefined;
  errorMessage?: string;
  disabled?: boolean | undefined;
  freeSolo?: boolean | undefined;
  open?: boolean | undefined;
  onFocus?: () => void | undefined;
  onBlur?: () => void | undefined;
  additionalOnChange?: (e: string) => void | undefined;
  onInputChange?: (event: React.SyntheticEvent, value: string, reason: string) => void | undefined;
  sx?: SxProps<Theme> | undefined;
  setText?: any;
  fullWidth?: boolean;
  getOptionDisabled?: (option: TOption) => boolean;
  noOptionsText?: any;
  debouncedCallbackDependencies?: any[];
  isSearch?: boolean;
}

function DebouncedAutoComplete<TOption>(props: DebouncedAutoCompleteProps<TOption>) {
  const {
    renderOption,
    value,
    options,
    getOptionsLabel,
    onSelectedOptionChanged,
    textboxLabel,
    textboxPlaceholder,
    onDebouncedTextChanged,
    required,
    error,
    errorMessage,
    disabled,
    freeSolo,
    open,
    onFocus,
    onBlur,
    additionalOnChange,
    onInputChange,
    sx,
    fullWidth,
    getOptionDisabled,
    noOptionsText,
    debouncedCallbackDependencies,
    isSearch
  } = props;

  const [textboxText, setTexboxText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const onDebounce = useCallback(
    debounce((text) => {
      onDebouncedTextChanged(text).then(() => setIsLoading(false));
    }, 500),
    debouncedCallbackDependencies || []
  );

  return (
    <FormControl fullWidth size="small">
      <Autocomplete
        disabled={disabled}
        freeSolo={freeSolo}
        value={((value as NonNullable<TOption>) || "") as any}
        open={open}
        size="small"
        fullWidth={fullWidth}
        loadingText={
          <div style={{ fontWeight: "bold", textAlign: "center", color: "#1f2b5e", borderRadius: "4px" }}>
            Loading...
          </div>
        }
        options={isLoading ? [] : options}
        disablePortal={true}
        getOptionLabel={(option) => getOptionsLabel(option as TOption)}
        renderOption={renderOption}
        onChange={(e, value: any) => {
          onSelectedOptionChanged(e, value);
        }}
        filterOptions={(o) => o} /* Don't filter the drop down options based on the text typed into the textbox*/
        onInputChange={(event, value, reason) => {
          if (onInputChange) {
            onInputChange(event, value, reason);
          }
        }}
        renderInput={(params) => (
          <TextField
            fullWidth={fullWidth}
            required={required}
            error={error}
            value={textboxText}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password" // disable autocomplete and autofill
            }}
            label={textboxLabel}
            placeholder={textboxPlaceholder}
            onChange={(e) => {
              if (additionalOnChange) {
                additionalOnChange(e.target.value);
              }
              setIsLoading(true);
              setTexboxText(e.target.value);
              props.setText && props.setText(e.target.value);
              onDebounce(e.target.value);
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            InputProps={{
              ...params.InputProps,
              startAdornment: isSearch ? <Icon icon="eva:search-fill" /> : null,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
            InputLabelProps={params.InputLabelProps}
            disabled={params.disabled}
            id={params.id}
            size={params.size}
          />
        )}
        loading={isLoading}
        sx={sx}
        noOptionsText={noOptionsText}
      />
    </FormControl>
  );
}

export default DebouncedAutoComplete;
