import { makeStyles } from "@material-ui/core/styles";
import styled from "@emotion/styled";

export const RootStyles = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  margin: 0 auto;
  max-width: 1440px;
  padding: 20px 0;
  .no-results_sorry {
    background-color: #fff;
    border-radius: 5px;
    padding: 32px 20px;
    text-align: center;
    h1 {
      font-size: 20px;
    }
    a {
      color: #00b8d4;
    }
    p {
      line-height: 1.6;
    }
  }
  > .main-wrap {
    max-height: calc(100vh - 170px);
    max-width: calc(100vw - 16px);
    background-color: transparent;
    height: 100%;
    border-radius: 5px;
  }
  @media (min-width: 900px) {
    padding: 40px 0;
    > .main-wrap {
      max-height: calc(100vh - 134px);
      max-width: 100%;
      height: auto;
    }
    .no-results_sorry {
      padding: 56px;
      h1 {
        font-size: 24px;
      }
    }
  }
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    flex: "0 1 auto",
    marginBottom: 24,
    position: "relative"
  },
  search: {
    marginLeft: 0,
    position: "relative",
    width: "100%",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    gap: "16px",

    "& input": { width: "100%", maxWidth: "100%" },
    "@media (max-width: 1023px)": {
      flexDirection: "column"
    }
  },
  advancedText: {
    cursor: "pointer",
    flex: "0 0 auto",
    height: "51px",
    backgroundColor: "#fff",
    padding: "0px 16px",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    "@media (max-width: 1023px)": {
      width: "100%",
      justifyContent: "space-between"
    },
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  inputRoot: {
    borderRadius: "8px 8px 0 0",
    overflow: "hidden",
    color: "inherit",
    width: "100%",
    flex: "1",
    "& input": { padding: theme.spacing(2, 1, 2, 1) },
    "& .MuiFilledInput-root": { backgroundColor: "#fff" },
    "& .MuiFilledInput-underline:after": { borderBottom: "2px solid black" }
  },
  viewOrders: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  indicator: {
    backgroundColor: "black",
    fontWeight: "bold"
  },
  tabPanel: {
    border: "1px solid red"
  },
  tabRoot: {
    minHeight: "48px"
  }
}));

export const AdvancedSearchWrap = styled.div<{ open: boolean }>`
  height: ${(props) => (props.open ? "450px" : "0px")};
  padding: ${(props) => (props.open ? "2px" : "0px")};
  overflow: ${(props) => (props.open ? "auto" : "hidden")};
  overflow: hidden;
  position: absolute;
  top: 120px;
  right: -4px;
  transition: height 0.15s ease-in-out;
  z-index: 150;
  @media (min-width: 1024px) {
    height: ${(props) => (props.open ? "372px" : "0px")};
    right: 2px;
    top: 60px;
  }
  > .inner_wrap {
    padding: 16px 24px 24px;
    background-color: #fff;
    border: 1px solid #1f2b5e;
    border-radius: 8px;
    box-shadow:
      0px 1px 2px rgba(46, 46, 54, 0.1),
      0px 1px 3px rgba(46, 46, 54, 0.05);
    width: 100%;
    height: 100%;
    margin: 2px;
    overflow: auto;
    width: 600px;
    max-width: calc(100vw - 16px);
    .fields_wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 32px;
      grid-row-gap: 16px;
    }
    .MuiButton-contained {
      box-shadow: none;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;
