import React from "react";
import { Delete } from "@material-ui/icons";
import { Button } from "@mui/material";
import Spinner from "../../../ui/Spinner";
import {
  ButtonsWrap,
  orangeButton,
  loadingStyles,
  customSecondaryStyles,
  customButtonStyles
} from "../../../ui/modalButtons";
import { SuccessWrap, CheckCircle, ComingSoonStyles } from "./phonebookContactStyles";
import axios from "axios";
import { useOrder } from "../../../Providers/OrderProvider";
import { Contact } from "../../../Providers/types";
import BlackListSearchAlert from "./BlackListSearchAlert";

interface IProps {
  submitSuccess: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit: boolean;
  isSubmitting: boolean;
  isSalesPerson: boolean;
  isAccountRep: boolean;
  handleCancel: () => void;
  handleSave: (isEdit: boolean) => Promise<void>;
  isClicked: boolean;
  setIsClicked: React.Dispatch<React.SetStateAction<boolean>>;
  addAccountReps: () => Promise<void>;
  loadingRemove: boolean;
  setLoadingRemove: React.Dispatch<React.SetStateAction<boolean>>;
  showDeleteConfirmation: boolean;
  setShowDeleteConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  deleteRef: React.MutableRefObject<any>;
  contact: Contact;
  editContact: Contact;
  setErrorMessage: React.Dispatch<any>;
  isContactUnderwriterOrTitleAgent: boolean;
  showBlackListSearchResultAlert: boolean;
  showPatriotSearchResultsAlert: boolean;
}

const AddEditOrderContactFooter = ({
  submitSuccess,
  setShowModal,
  isEdit,
  isSubmitting,
  isSalesPerson,
  isAccountRep,
  handleCancel,
  handleSave,
  isClicked,
  setIsClicked,
  addAccountReps,
  loadingRemove,
  setLoadingRemove,
  showDeleteConfirmation,
  setShowDeleteConfirmation,
  deleteRef,
  contact,
  editContact,
  setErrorMessage,
  isContactUnderwriterOrTitleAgent,
  showBlackListSearchResultAlert,
  showPatriotSearchResultsAlert
}: IProps) => {
  const { order, getOrderContacts } = useOrder();

  const handleDeleteContact = async () => {
    setLoadingRemove(true);
    const { data } = await axios.post("/api/orders/deleteordercontact", {
      orderNumber: order.orderNumber,
      contactCode: editContact.code
    });
    setLoadingRemove(false);
    if (data.errorMessage) {
      setErrorMessage(data.errorMessage);
    } else {
      getOrderContacts();
      setShowModal(false);
    }
  };

  const renderLeftButtons = () => {
    if (isContactUnderwriterOrTitleAgent || isSalesPerson) {
      return <div />;
    }
    return (
      <Button
        sx={[orangeButton, loadingRemove && loadingStyles]}
        variant="contained"
        disableElevation
        onClick={() => setShowDeleteConfirmation(true)}
      >
        <Delete />
        <span>Remove from Order</span>
        <Spinner local={true} display={loadingRemove} />
      </Button>
    );
  };

  const renderSaveButton = () => {
    if (isAccountRep) {
      return (
        <Button
          onClick={() => {
            if (isAccountRep) {
              addAccountReps();
            }
            setIsClicked(true);
          }}
          sx={customButtonStyles}
          disabled={isClicked}
          variant="contained"
          color="primary"
        >
          <span>{isClicked ? "Saving.." : "Save"}</span>
        </Button>
      );
    }
    return (
      <Button
        disabled={isSubmitting || (isEdit && !contact?.code && !isSalesPerson)}
        onClick={() => (isEdit ? handleSave(true) : handleSave(false))}
        variant="contained"
        color="primary"
        sx={customButtonStyles}
      >
        {isSubmitting && !submitSuccess ? <span>Saving...</span> : <span>{isEdit ? "Save" : "Add"}</span>}
      </Button>
    );
  };

  return (
    <div className="buttonsWrap">
      {submitSuccess && !showBlackListSearchResultAlert && !showPatriotSearchResultsAlert && (
        <SuccessWrap>
          <div className="df aic gap2">
            <CheckCircle />
            <div>
              {isEdit ? "Contact was updated to order successfully" : "Contact was added to order successfully"}
            </div>
          </div>
          <button onClick={() => setShowModal(false)}>Close</button>
        </SuccessWrap>
      )}
      {!submitSuccess && (
        <ButtonsWrap style={{ paddingRight: 0, justifyContent: "space-between" }}>
          {isEdit ? (
            <>
              {renderLeftButtons()}
              <ComingSoonStyles show={showDeleteConfirmation} ref={deleteRef}>
                <div className="comingSoonInner">
                  <div className="coming-soon">Are you sure?</div>
                  <div>
                    <span style={{ fontWeight: "bold" }}>{contact?.name}</span>
                    {contact?.type && <span> - {contact?.type} - </span>}
                    will be deleted from the order.
                  </div>
                  <div className="df jcfe mt5 gap1">
                    <Button sx={customSecondaryStyles} onClick={() => setShowDeleteConfirmation(false)}>
                      cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="info"
                      size="small"
                      onClick={handleDeleteContact}
                      sx={customButtonStyles}
                    >
                      Okay
                    </Button>
                  </div>
                  <Spinner local={true} display={loadingRemove} />
                </div>
              </ComingSoonStyles>
            </>
          ) : (
            <div></div>
          )}
          <div>
            <Button sx={customSecondaryStyles} disableElevation onClick={handleCancel} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            {renderSaveButton()}
          </div>
        </ButtonsWrap>
      )}
    </div>
  );
};

export default AddEditOrderContactFooter;
