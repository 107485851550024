import React, { useEffect, useMemo, useState } from "react";
import {
  FormControl,
  TextField,
  Autocomplete,
  Stack,
  IconButton,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Alert
} from "@mui/material";
import axios from "axios";

import {
  FormWrapStyles,
  ComingSoonStyles,
  ContactOptionWrap,
  AdditionalEmailsWrap,
  IframeWrap
} from "./phonebookContactStyles";
import { useOrder } from "../../../Providers/OrderProvider";
import DebouncedAutoComplete from "../../../ui/DebouncedAutoComplete";
import ContactCard from "./ContactCard";
import { AddIcon, Delete } from "../../../ui/icons";
import ClientPhonebookIFrame from "../ClientPhonebookIFrame";
import { isDevelopment } from "../../../helpers/envChecker";
import PhonebookWarning from "./PhonebookWarning";
import AddNewContactOption from "./AddNewContactOption";
import { Icon } from "@iconify/react";
import { CompanyBranch, Contact, ContactType, IFramePageViewTypes, Rep } from "../../../Providers/types";
import validateEmail from "../../../utils/validateEmail";

export default function PhonebookContact({
  isEdit,
  editContact,
  setSelectedAccountsRep,
  accountRepList,
  isAttorney,
  isSalesPerson,
  isAccountRep,
  contactType,
  setContactType,
  contact,
  setContact,
  selectedCompanyBranch,
  setSelectedCompanyBranch,
  isDisplayPhonebookIframe,
  setIsDisplayPhonebookIframe,
  isNewContact,
  nameOverride,
  setNameOverride,
  invalidEmailIndexes,
  setInvalidEmailIndexes,
  isPrincipalBuyer,
  isPrincipalSeller,
  updatedDealOwner,
  setUpdatedDealOwner,
  originalDealOwner
}: {
  isEdit: boolean;
  editContact?: Contact;
  setSelectedAccountsRep: React.Dispatch<React.SetStateAction<Rep>>;
  accountRepList: Rep[];
  isAttorney: boolean;
  isSalesPerson: boolean;
  isAccountRep: boolean;
  contactType: ContactType;
  setContactType: React.Dispatch<React.SetStateAction<ContactType>>;
  contact: Contact;
  setContact: React.Dispatch<React.SetStateAction<Contact>>;
  selectedCompanyBranch: CompanyBranch;
  setSelectedCompanyBranch: React.Dispatch<React.SetStateAction<CompanyBranch>>;
  isDisplayPhonebookIframe: boolean;
  setIsDisplayPhonebookIframe: React.Dispatch<React.SetStateAction<boolean>>;
  isNewContact?: boolean;
  nameOverride: string | null;
  setNameOverride: React.Dispatch<React.SetStateAction<string>>;
  invalidEmailIndexes: number[];
  setInvalidEmailIndexes: React.Dispatch<React.SetStateAction<number[]>>;
  isPrincipalBuyer: boolean;
  isPrincipalSeller: boolean;
  updatedDealOwner: string;
  setUpdatedDealOwner: React.Dispatch<React.SetStateAction<string>>;
  originalDealOwner: string;
}) {
  const { order, setOrder, contactTypeList } = useOrder();
  const [contactList, setContactList] = useState<Contact[]>([]);
  const [searchedContact, setSearchedContact] = useState<string>(null);
  const [selectedContactToAdd, setSelectedContactToAdd] = useState<string>(null);
  const [isDisplayPhonebookWarning, setIsDisplayPhonebookWarning] = useState(false);
  const [phonebookIFramePageView, setPhonebookIFramePageView] = useState<IFramePageViewTypes>("view-contact");
  const [representsContact, setRepresentsContact] = useState<Contact[]>([]);
  const [companyBranchList, setCompanyBranchList] = useState<CompanyBranch[]>([selectedCompanyBranch]);
  const [isValid, setIsValid] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const isFunder = contactType?.name?.toLowerCase() == "funder";
  const isCompany = contactType?.name?.toString().toLowerCase().includes("lender");
  const isInternalUserContact = isAccountRep || isSalesPerson || isFunder;

  const closePhonebookIFrame = () => {
    setIsDisplayPhonebookIframe(false);
    setPhonebookIFramePageView("view-contact");
  };

  const defaultContact: Contact = {
    id: null,
    address1: "",
    address2: "",
    cityStateZip: "",
    city: "",
    state: "",
    zip: "",
    code: "",
    email: "",
    emailsSplit: [],
    fax: "",
    isGroup: false,
    isMarketingSource: false,
    lookupCode: "",
    name: "",
    notes: "",
    payeeName: "",
    phone: "",
    cell: "",
    referenceNumber: "",
    represents: "",
    type: "",
    url: "",
    role: "",
    company: "",
    salesRep: "",
    otherContactType: "",
    isNew: true,
    contactType: "",
    emailPolicyTo: ""
  };

  const getContactOptions = (
    isDisplayPhonebookIframe: boolean,
    searchedContact: string,
    defaultContact: Contact,
    contactList: Contact[],
    isInternalUserContact: boolean
  ) => {
    if (isDisplayPhonebookIframe) {
      return [];
    }
    if (searchedContact && !isInternalUserContact) {
      return [defaultContact, ...contactList];
    }

    return contactList;
  };

  const handleAddNewContactClick = () => {
    setContactList([]);
    setPhonebookIFramePageView(isCompany ? "create-company" : "create-contact");
    setSelectedContactToAdd(searchedContact);
    setSearchedContact(null);
    setIsDisplayPhonebookIframe(true);
  };

  const getAlertMessage = () => {
    if (originalDealOwner === "Buyer" && isPrincipalSeller) {
      return "Buyer was previously marked as Deal Owner! Saving will switch to seller!";
    }
    if (originalDealOwner === "Seller" && isPrincipalBuyer) {
      return "Seller was previously marked as Deal Owner! Saving will switch to Buyer!";
    }
    return "";
  };

  const handleCheckboxChange = async (event) => {
    const newDealOwner = event.target.checked ? (isPrincipalBuyer ? "Buyer" : "Seller") : null;

    if (originalDealOwner !== null && originalDealOwner !== newDealOwner && newDealOwner !== null) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
    setUpdatedDealOwner(newDealOwner);
  };
  const representsList = useMemo(
    () =>
      [
        { name: "All Buyers", code: "ba", type: null } as Contact,
        { name: "All Sellers", code: "sa", type: null } as Contact,
        { name: "Middle Party", code: "mp", type: null } as Contact,
        { name: "End Buyer", code: "eb", type: null } as Contact,
        ...order.contacts
          .filter(
            (c) =>
              c.code &&
              c.name !== contact?.name &&
              !c.type.toLowerCase().includes("attorney") &&
              c.code.toLowerCase() !== "sa" &&
              c.code.toLowerCase() !== "ba" &&
              (c.type.toLowerCase().includes("buyer") ||
                c.type.toLowerCase().includes("seller") ||
                c.type.toLowerCase().includes("lender"))
          )
          .map(({ name, code, type }) => ({ name, code, type }))
      ] as Contact[],
    [order.contacts]
  );

  const setRepresentsField = async (contactType: ContactType) => {
    const attorneyType = contactTypeList.find((t) => t.name === "Attorney");
    const isBuyersAttorney = contactType?.name === "Buyer's Attorney";
    if (contactType?.name === "Buyer's Attorney" || contactType?.name === "Seller's Attorney") {
      setContact({
        ...contact,
        represents: isBuyersAttorney ? "ba" : "sa",
        type: contactType?.name,
        code: contact?.code
      });
      setContactType(attorneyType);
      const isBuyer = contactType?.name?.toLowerCase().includes("buyer");
      const rep = representsList.find((c) => (isBuyer ? c.name === "All Buyers" : c.name === "All Sellers"));
      if (rep) {
        setRepresentsContact([rep]);
      }
    }
    if (contactType?.name === "Lender's Attorney") {
      const firstLender = representsList.find((c) => c.type?.toLowerCase().includes("lender"));
      if (firstLender) {
        setContact({
          ...contact,
          represents: firstLender?.code,
          type: contactType?.name,
          code: contact?.code
        });
        setRepresentsContact([firstLender]);
      }
      setContactType(attorneyType);
    }
  };

  const onContactSearchTextChanged = async (text: string) => {
    if (!text || text.length < 3) {
      setContactList([]);
      return;
    }
    const actualContactType = contactType.name.toLowerCase().includes("attorney") ? "attorney" : contactType.name;
    const baseUrl = "/api/orders";
    const endpoint =
      actualContactType.toLowerCase() === "funder"
        ? `${baseUrl}/getFundersFromPhonebook?searchText=${encodeURIComponent(text)}`
        : `${baseUrl}/search?contactType=${encodeURIComponent(actualContactType)}&searchTerm=${encodeURIComponent(
            text
          )}&isOther=${contactType.isOther}`;

    const { data } = await axios.get(endpoint);

    const processedContacts = data.map((contact) => ({
      ...contact,
      emailsSplit: contact.email ? contact.email.split(";") : []
    }));
    setContactList(processedContacts);
  };

  const getCompanyBranches = async (
    isIndividual: boolean,
    companyId?: number | undefined,
    branchId?: number | null | undefined
  ) => {
    if ((isIndividual && !branchId) || (!isIndividual && !companyId)) {
      setCompanyBranchList([]);
      setSelectedCompanyBranch(null);
      return;
    }

    let endpoint = isIndividual
      ? `/api/orders/getCompanyBranchForMomentum/${branchId}`
      : `/api/orders/getCompanyBranchesForMomentum/${companyId}`;

    const { data } = await axios.get<CompanyBranch[] | CompanyBranch>(endpoint);

    return data;
  };

  const handleContactChange = async (c: Contact) => {
    setContact({
      ...c,
      isMarketingSource: contact?.isMarketingSource,
      type: contact?.type,
      represents: contact?.represents,
      code: contact?.code
    } as Contact);
    //get and set branches/locations
    const branchResults = await getCompanyBranches(c.isIndividual, c.id, c.branchId);
    let companyBranches: CompanyBranch[];

    if (Array.isArray(branchResults)) {
      companyBranches = branchResults.filter((d) => d.address1 || d.address2);
    } else {
      companyBranches = branchResults?.address1 || branchResults?.address2 ? [branchResults] : []; // Wrap individual data in an array
    }

    const defaultBranch = companyBranches.find((d) => d.isHeadquarters) || companyBranches[0];

    setCompanyBranchList(companyBranches);
    setSelectedCompanyBranch(defaultBranch);
  };

  useEffect(() => {
    (async () => {
      if (isEdit && contact.branchId) {
        const branchResults = await getCompanyBranches(contact.isIndividual, contact.id, contact.branchId);
        if (Array.isArray(branchResults)) {
          setCompanyBranchList(branchResults);
        } else {
          setCompanyBranchList([branchResults]);
        }
      }
    })();
  }, [contact.branchId]);

  useEffect(() => {
    isAttorney && setRepresentsField(contactType);
  }, [contactType]);

  const contactSearchMode = !contact.name;
  //FOR IFRAME
  useEffect(() => {
    const domain = isDevelopment() ? "localhost:3000" : "titleq.mcres.com";
    // const domain = 'titleq.mcres.com';
    const handleIFrameEvent = (event) => {
      const titleQDomain = `https://${domain}`;
      const { eventType, phonebookId } = event.data ?? {};
      if (event.origin !== titleQDomain) return;
      if (eventType == "cancel") {
        closePhonebookIFrame();
        return;
      }

      const isContactEvent = eventType === "addContact" || eventType === "editContact";
      const isCompanyEvent = eventType === "editCompany" || eventType === "addCompany";

      if (phonebookId && (isContactEvent || isCompanyEvent)) {
        const endpoint = isContactEvent
          ? `/api/orders/getMomentumContactFromPhonebook?id=${phonebookId}`
          : `/api/orders/getMomentumCompanyContactFromPhonebook?id=${phonebookId}`;

        (async () => {
          const { data } = await axios.get(endpoint);
          setContact((contact) => ({ ...contact, ...data }));
          closePhonebookIFrame();
        })();
      }
    };

    // Add event listener for message events
    window.addEventListener("message", handleIFrameEvent);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("message", handleIFrameEvent);
    };
  }, []);

  const getDealOwnerCheckboxLabel = () => {
    if (isPrincipalBuyer && originalDealOwner === null) {
      return "Mark Buyer as Deal Owner";
    } else if (isPrincipalSeller && originalDealOwner === null) {
      return "Mark Seller as Deal Owner";
    } else if (
      (isPrincipalBuyer && originalDealOwner === "Buyer") ||
      (isPrincipalSeller && originalDealOwner === "Seller")
    ) {
      return "Mark as Deal Owner";
    } else if (isPrincipalBuyer && originalDealOwner === "Seller") {
      return "Switch Deal Owner from Seller to Buyer";
    } else if (isPrincipalSeller && originalDealOwner === "Buyer") {
      return "Switch Deal Owner from Buyer to Seller";
    }
  };
  return (
    <>
      {isAttorney && (
        <FormControl sx={{ maxWidth: 300, width: "100%" }} size="small">
          <Autocomplete
            value={representsContact}
            options={representsList}
            getOptionLabel={(option) => {
              if (typeof option === "object") {
                let actualOption = option[0];
                if (!actualOption) {
                  actualOption = option;
                }
                if (actualOption?.name) {
                  return `${actualOption?.type ? actualOption?.type + " - " : ""}${actualOption?.name}`;
                }
                if (!actualOption || !actualOption.type) {
                  return "";
                }
                return `${actualOption?.type} (${actualOption?.code})`;
              }
              return "";
            }}
            size="small"
            onChange={(e, value: any) => {
              setContact({
                ...contact,
                represents: value.code,
                code: contact?.code
              });
              setRepresentsContact([value]);
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" variant="outlined" label="Represents" placeholder="Start typing..." />
            )}
          />
        </FormControl>
      )}
      <div className="df full-width mt5 jcsb">
        {contactType?.isPhonebookContact && (
          <ContactCard
            contact={contact}
            nameOverride={nameOverride}
            isDisplayEditIcon={!isInternalUserContact}
            editClick={() => {
              if (!contact?.lookupCode) {
                setIsDisplayPhonebookWarning(true);
                return;
              }
              setPhonebookIFramePageView(contact.isIndividual ? "view-contact" : "view-company");
              setIsDisplayPhonebookIframe(true);
            }}
            selectedCompanyBranch={selectedCompanyBranch}
          />
        )}
        <FormWrapStyles>
          <div style={{ marginTop: 8, maxWidth: "100%", width: "100%" }}>
            {contactType?.isPhonebookContact && !isAccountRep && !isSalesPerson && (
              <FormControl
                size="small"
                fullWidth
                sx={{
                  'button[aria-label="Clear"]': { visibility: "initial" },
                  'button[aria-label="Open"]': { display: "none" },
                  'button[aria-label="Close"]': { display: "none" },
                  ".MuiAutocomplete-endAdornment": {
                    display: contact?.name ? "block" : "none"
                  },
                  ".MuiAutocomplete-popper": {
                    transform: "none !important",
                    top: "44px !important",
                    width: "600px !important",
                    right: "-20px !important",
                    left: "auto !important",
                    border: contactList?.length && "1px solid #ccc",
                    borderRadius: "8px",
                    overflow: "hidden"
                  }
                }}
              >
                <DebouncedAutoComplete
                  value={contact}
                  fullWidth={true}
                  options={getContactOptions(
                    isDisplayPhonebookIframe,
                    searchedContact,
                    defaultContact,
                    contactList,
                    isInternalUserContact
                  )}
                  getOptionsLabel={(option) => option?.name || ""}
                  onSelectedOptionChanged={(_e, c) => {
                    if (!c) {
                      setSearchedContact(null);
                      setSelectedContactToAdd(null);
                    }
                    if (typeof c === "object" && !c.isNew) {
                      handleContactChange(c);
                    }
                  }}
                  onInputChange={(event, value, reason) => {
                    if (reason == "clear") {
                      setSearchedContact(null);
                      setSelectedContactToAdd(null);
                      setContact({
                        ...defaultContact,
                        isMarketingSource: contact?.isMarketingSource,
                        type: contact?.type,
                        represents: contact?.represents,
                        code: contact?.code
                      } as Contact);
                    }
                  }}
                  noOptionsText=""
                  renderOption={(props, option) => {
                    return option.isNew ? (
                      <AddNewContactOption searchedContact={searchedContact} onClick={handleAddNewContactClick} />
                    ) : (
                      <ContactOption option={option} props={props} isInternalUserContact={isInternalUserContact} />
                    );
                  }}
                  textboxLabel={`Search for ${contactType?.name}...`}
                  textboxPlaceholder="Search the phonebook/Lookup..."
                  onDebouncedTextChanged={(text) => onContactSearchTextChanged(text)}
                  debouncedCallbackDependencies={[contactType]}
                  additionalOnChange={(text) => setSearchedContact(text)}
                  isSearch={contactSearchMode}
                />
              </FormControl>
            )}
          </div>
          {contactType.name === "Lender" && (
            <TextField
              fullWidth
              size="small"
              label="Lender Name Override"
              sx={{ marginTop: 2 }}
              value={nameOverride || ""}
              onChange={(e) => setNameOverride(e.target.value)}
              onFocus={() => {
                if (contactType.name === "Lender" && contact?.name && nameOverride !== contact.name) {
                  setNameOverride(contact.name);
                }
              }}
            />
          )}
          <Autocomplete
            value={selectedCompanyBranch as any}
            options={companyBranchList}
            getOptionLabel={(option: CompanyBranch) =>
              option?.address1
                ? `${option?.address1}${
                    option?.isHeadquarters && companyBranchList.length > 1 ? " (Headquarters)" : ""
                  }`
                : ""
            }
            onChange={(e, value: any) => {
              setSelectedCompanyBranch(value);
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} label="Address" placeholder="Choose an address..." />
            )}
            size="small"
            sx={{ marginTop: 2 }}
          />

          {contact?.emailsSplit?.length > 0 && (
            <AdditionalEmailsWrap>
              <div className="sdfdsfsd">
                <span>Emails</span>
              </div>
              <div className="new_emails">
                {contact?.emailsSplit?.length &&
                  contact?.emailsSplit.map((email, index) => (
                    <div key={index} className="g1 input_wrap">
                      <TextField
                        type="text"
                        size="small"
                        label={index === 0 ? "Email" : `CC ${index}`}
                        disabled={index === 0}
                        value={email}
                        error={invalidEmailIndexes.includes(index)}
                        helperText={invalidEmailIndexes.includes(index) && "Invalid Email"}
                        onFocus={() => setIsValid(true)}
                        fullWidth
                        onChange={(e) => {
                          setInvalidEmailIndexes(invalidEmailIndexes.filter((i) => i !== index));
                          const emails = [...contact.emailsSplit];
                          emails[index] = e.target.value;
                          setContact({ ...contact, emailsSplit: emails });
                        }}
                        onBlur={(e) => {
                          if (!validateEmail(e.target.value)) {
                            setInvalidEmailIndexes([...invalidEmailIndexes, index]);
                          }
                        }}
                      />
                      <IconButton
                        style={index === 0 ? { visibility: "hidden" } : {}}
                        aria-label="delete"
                        onClick={() => {
                          const emailsSplit = [...contact.emailsSplit];
                          emailsSplit.splice(index, 1);
                          setContact({ ...contact, emailsSplit });
                        }}
                      >
                        <Delete className="delete_icon" />
                      </IconButton>
                    </div>
                  ))}
                <div
                  className="add-button"
                  onClick={() =>
                    setContact({
                      ...contact,
                      emailsSplit: [...contact.emailsSplit, ""]
                    })
                  }
                >
                  <AddIcon />
                  <div>Add a CC</div>
                </div>
              </div>
            </AdditionalEmailsWrap>
          )}
          {isAccountRep && (
            <div className="text-input_wrap full_width">
              <Autocomplete
                options={accountRepList}
                getOptionLabel={(option: Rep) => option.fullName}
                onChange={(e, value: any) => {
                  setSelectedAccountsRep(value);
                }}
                renderInput={(params) => (
                  <TextField size="small" {...params} label="Account Rep" placeholder="Start typing..." />
                )}
              />
            </div>
          )}
          <ComingSoonStyles show={isDisplayPhonebookWarning}>
            <div className="comingSoonInner">
              <PhonebookWarning onClick={() => setIsDisplayPhonebookWarning(false)} />
            </div>
          </ComingSoonStyles>
        </FormWrapStyles>
      </div>
      {(isPrincipalBuyer || isPrincipalSeller) && (
        <Box sx={{ width: "100%", textAlign: "left", marginTop: 4 }}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={
                  (updatedDealOwner === "Buyer" && isPrincipalBuyer) ||
                  (updatedDealOwner === "Seller" && isPrincipalSeller)
                }
                onChange={handleCheckboxChange}
              />
            }
            label={getDealOwnerCheckboxLabel()}
          />
          {showAlert && (
            <Alert sx={{ marginTop: 1, marginBottom: 1 }} severity="warning">
              {getAlertMessage()}
            </Alert>
          )}
        </Box>
      )}
      {isDisplayPhonebookIframe && (
        <IframeWrap isNewContact={true}>
          <div className="iframeInner">
            {contactType?.isPhonebookContact && (
              <ContactCard
                contact={contact}
                editClick={() => {
                  if (!contact?.lookupCode || isInternalUserContact) {
                    setIsDisplayPhonebookWarning(true);
                    return;
                  }
                  setPhonebookIFramePageView(contact.isIndividual ? "view-contact" : "view-company");
                  setIsDisplayPhonebookIframe(true);
                }}
                selectedCompanyBranch={selectedCompanyBranch}
                nameOverride={nameOverride}
              />
            )}

            <ClientPhonebookIFrame
              lookupCode={contact.lookupCode}
              page={phonebookIFramePageView}
              defaultName={
                phonebookIFramePageView == "create-contact" || phonebookIFramePageView == "create-company"
                  ? selectedContactToAdd
                  : ""
              }
              defaultCompanyType={phonebookIFramePageView == "create-company" ? contactType.name : ""}
            />
          </div>
        </IframeWrap>
      )}
    </>
  );
}

function ContactOption({
  option,
  props,
  isInternalUserContact
}: {
  option: Contact;
  props: any;
  isInternalUserContact: boolean;
}) {
  const grey = "#212B36";
  return (
    <Stack
      direction="row"
      justifyContent="space-between !important"
      padding={2}
      alignItems="normal"
      {...props}
      key={option.lookupCode}
    >
      <Box>
        <Stack direction="row" alignItems="center">
          <Icon
            icon={option.isIndividual ? "mdi:account" : "heroicons-outline:office-building"}
            fontSize={24}
            style={{ marginRight: "16px" }}
          />
          <Box>
            <Typography variant="subtitle2" fontWeight={600}>
              {`${option?.name || ""} `}
              {!isInternalUserContact && (
                <Typography variant="caption">{!option.isIndividual ? `(Company)` : `(Individual)`}</Typography>
              )}
            </Typography>
            <Typography variant="subtitle2" fontWeight={300} color={grey}>
              {option?.email || ""}
            </Typography>
            <Typography variant="subtitle2" fontWeight={300} color={grey}>
              {option?.phone || ""}
            </Typography>
          </Box>
        </Stack>
      </Box>
      {!isInternalUserContact && (
        <Box textAlign="right">
          <Typography variant="subtitle2" fontWeight={300} color={grey}>
            {option.isIndividual && option.role ? `${option.role} ${option.company ? "at" : ""}` : ""}
          </Typography>
          {option.company && option.isIndividual && (
            <Typography color={grey}>
              <Icon icon="heroicons-outline:office-building" style={{ marginRight: 8 }} />
              {option.company}
            </Typography>
          )}
          <Typography variant="subtitle2" fontWeight={300} color={grey}>
            {option?.address1 || ""}
          </Typography>
        </Box>
      )}
    </Stack>
  );
}
