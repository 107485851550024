/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";
import { Fragment, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Typography } from "@mui/material";

import { useOrder } from "../Providers/OrderProvider";
import { Copy, DownCarot, EditIcon, Mail, PhoneCallingIcon, CellPhoneIcon } from "./icons";
import AddEditOrderContact from "../pages/new-home/contacts/AddEditOrderContact";
import { useUser } from "../Providers/UserProvider";
import { Root, Star } from "./contactCardStyles";

export default function ContactCard({
  code,
  lookupCode,
  name,
  type,
  address1,
  address2,
  cityStateZip,
  city,
  state,
  zip,
  isMarketingSource,
  phone,
  cell,
  fax,
  email,
  notes,
  payeeName,
  represents,
  company,
  licenseNumber,
  buyerSellerInfo
}) {
  const { order } = useOrder();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [contact, setContact] = useState();
  const [key, setKey] = useState(0);
  const { userEmail } = useUser();

  useEffect(() => {
    resetContact();
  }, [order]);

  const resetContact = () => {
    setContact({
      code,
      lookupCode,
      name,
      isMarketingSource,
      type,
      address1,
      address2,
      city,
      state,
      zip,
      phone,
      fax,
      email,
      notes,
      payeeName,
      represents,
      company,
      licenseNumber,
      cell,
      emailsSplit: email?.split(';') || [],
      buyerSellerInfo
    });
  };

  const handleEditContact = () => {
    setKey(key + 1); /* Is this actually doing anything? Doesn't look like it */
    setIsEdit(true);
  };

  const renderEditButton = () => {
    const isSalesPerson = type?.toLowerCase() == "sales person";
    const folksNotAllowedToEditSalesReps = [
      "cmann@madisontitle.com",
      "jquinn@madisontitle.com",
      "jlowi@madisontitle.com"
    ];
    const isUserAllowedToEditSalesRep = !folksNotAllowedToEditSalesReps.includes(userEmail);
    if (
      (isSalesPerson && isUserAllowedToEditSalesRep) ||
      (code && !['TitleCompany'].includes(type))
    ) {
      return (
        <div onClick={handleEditContact} className="editIcon">
          <EditIcon />
        </div>
      );
    }
  };

  const grey = "#212B36";
  return (
    <Root isExpanded={isExpanded}>
      <div className="df aic jcsb">
        <div className="df aic">
          {isMarketingSource && <Star className="kochav" />}
          <div className="relationship">{type}</div>
        </div>
        <div className="df aic">
          <div className="edit-contact">
            {renderEditButton()}
            {email && (
              <Fragment>
                <div className="copy_email">
                  <Copy onClick={() => navigator.clipboard.writeText(email)} />
                </div>
                <a href={`mailto:${email}?subject=${encodeURIComponent(order?.emailSubjectLine)}`}  className="send_email">
                  <Mail />
                </a>
              </Fragment>
            )}
            {phone && (
              <a
                href={`tel:${phone}`}
                className="call_em"
                css={{ "&:hover": { "&:before": { content: `'${phone}'` } } }}
              >
                <PhoneCallingIcon />
              </a>
            )}
            {cell && (
              <a href={`tel:${cell}`} className="cell_em"  css={{ "&:hover": { "&:before": { content: `'${cell}'` } } }}>
                <CellPhoneIcon />
              </a>
            )}
          </div>
          {phone || email || notes || address1 || address2 || cityStateZip ? (
            <DownCarot className="down_carot" onClick={() => setIsExpanded(!isExpanded)} />
          ) : (
            <DownCarot className="down_carot-disabled" />
          )}
        </div>
      </div>
      <div className="df aic jcsb">
        <div className="name" style={{ marginTop: name ? "16px" : "" }}>
          {name}
        </div>
        {payeeName && name !== payeeName && (
          <div className="companyName">
            <div color={grey} style={{ display: "flex", alignItems: "center" }}>
              <Icon icon="heroicons-outline:office-building" style={{ marginRight: 4 }} />
              {payeeName}
            </div>
          </div>
        )}
      </div>
      <div className="all-info-wrap">
        <div className="info-wrap mt2">
          <div>
            {email && (
              <a className="mb1 df" href={`mailto:${email}?subject=${order.emailSubjectLine}`}>
                {email}
              </a>
            )}
          </div>
          <div>
            {phone && (
              <a className="mb1 df" href={`tel:${phone}`}>
                {phone}
              </a>
            )}
          </div>
          {cell && (
            <span>
              <a href={`tel:${cell}`}>Cell: {cell}</a>
            </span>
          )}
        </div>
        <div className="mt2">
          {(address1 || cityStateZip) && (
            <div className="addressFull">
              <span>{address1}</span>
              {address2 && <span>{address2}</span>}
              <div>{cityStateZip}</div>
            </div>
          )}
        </div>
      </div>
      {notes && (
        <div style={{ marginTop: 10, width: "100%" }}>
          <span>
            <strong>Notes:</strong> {notes}
          </span>
        </div>
      )}

      {isEdit && <AddEditOrderContact key={key} isEdit={true} editContact={contact} />}
    </Root>
  );
}
