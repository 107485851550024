import styled from "@emotion/styled";

export const ButtonsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-right: 16px;
`;

export const BaseButton = styled.button<{
  isLoading?: boolean;
  isDisabled?: boolean;
}>`
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 0 12px;
    height: 32px;
    font-weight: 500;
  }
  align-items: center;
  background-color: ${(props) => (props.isDisabled ? "#e6e6e6" : "#1f2b5e")};
  border-radius: 8px;
  box-shadow:
    0px 0px 0px 1px transparent inset,
    0px 0em 0px 0px rgb(34 36 38 / 15%) inset;
  color: #fff;
  cursor: ${(props) => (props.isLoading || props.isDisabled ? "default" : "pointer")};
  display: flex;
  font-weight: 500;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.5px;
  margin-right: 16px;
  min-width: 80px;
  padding: 0 24px;
  position: relative;
  /* text-transform: capitalize; */
  transition:
    opacity 0.1s ease,
    background-color 0.1s ease,
    color 0.1s ease,
    box-shadow 0.1s ease,
    background 0.1s ease,
    -webkit-box-shadow 0.1s ease;
  /* opacity: ${(props) => props.isLoading && "0.5"}; */
  pointer-events: ${(props) => props.isLoading || (props.isDisabled && "none")};
  :last-of-type {
    margin-right: 0;
  }
  :hover {
    background-color: ${(props) => (props.isDisabled ? "#e6e6e6" : "#000e48")};
    box-shadow:
      0px 0px 0px 1px transparent inset,
      0px 0em 0px 0px rgb(34 36 38 / 15%) inset;
  }
`;

export const GhostButton = styled.button`
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 0 12px;
    height: 32px;
    font-weight: 500;
  }
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #c2c5d3;
  box-shadow:
    0px 0px 0px 1px transparent inset,
    0px 0em 0px 0px rgb(34 36 38 / 15%) inset;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  height: 40px;
  justify-content: center;
  margin-right: 16px;
  min-width: 80px;
  padding: 0 24px;
  position: relative;
  text-transform: capitalize;
  transition:
    opacity 0.1s ease,
    background-color 0.1s ease,
    color 0.1s ease,
    box-shadow 0.1s ease,
    background 0.1s ease,
    -webkit-box-shadow 0.1s ease;
  :last-of-type {
    margin-right: 0;
  }
  :hover {
    color: rgba(0, 0, 0, 1);
    border: 1px solid #999fb6;
    box-shadow:
      0px 0px 0px 1px transparent inset,
      0px 0em 0px 0px rgb(34 36 38 / 15%) inset;
  }
`;

export const BlueGhostButton = styled(GhostButton)`
  color: #1f2b5e;
  border: 1px solid #1f2b5e;
  width: 100%;
  :hover {
    color: #1f2b5e;
    background-color: #e6e6e6;
  }
`;

export const BlueModalButton = styled(BaseButton)`
  color: #fff;
  background-color: rgba(31, 43, 94, 0.12);

  :hover {
    background-color: rgba(31, 43, 94, 1);
  }
`;

export const VerticalBaseButton = styled.button<{
  isLoading?: boolean;
  isDisabled?: boolean;
}>`
  align-items: center;
  background-color: ${(props) => (props.isDisabled ? "#e6e6e6" : "#1f2b5e")};
  border-radius: 4px;
  box-shadow:
    0px 0px 0px 1px transparent inset,
    0px 0em 0px 0px rgb(34 36 38 / 15%) inset;
  color: #fff;
  cursor: ${(props) => (props.isLoading || props.isDisabled ? "default" : "pointer")};
  display: flex;
  font-weight: 600;
  font-size: 16px;

  justify-content: center;
  margin-right: 16px;
  padding: 16px;
  position: relative;
  text-transform: capitalize;
  transition:
    opacity 0.1s ease,
    background-color 0.1s ease,
    color 0.1s ease,
    box-shadow 0.1s ease,
    background 0.1s ease,
    -webkit-box-shadow 0.1s ease;
  pointer-events: ${(props) => props.isLoading || (props.isDisabled && "none")};
  :last-of-type {
    margin-right: 0;
  }
  :hover {
    background-color: ${(props) => (props.isDisabled ? "#e6e6e6" : "#00b8d4")};
    box-shadow:
      0px 0px 0px 1px transparent inset,
      0px 0em 0px 0px rgb(34 36 38 / 15%) inset;
    cursor: pointer;
  }
`;
export const GreenModalButton = styled(BaseButton)`
  color: #fff;
  background-color: ${(props) => (props.isDisabled ? "#e6e6e6" : "rgba(17, 185, 84, 0.9)")};
  :hover {
    background-color: ${(props) => (props.isDisabled ? "#e6e6e6" : "rgba(17, 185, 84, 1)")};
  }
`;

export const RedModalButton = styled(BaseButton)`
  color: #fff;
  background-color: rgba(255, 0, 0, 0.9);
  :hover {
    background-color: rgba(255, 0, 0, 1);
  }
`;

export const GhostButtonLink = GhostButton.withComponent("a");

export const TitleQButton = styled.button`
  align-items: center;
  background-color: #1f2b5e;
  border-radius: 8px;
  border: 0px;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  font-weight: 600;
  gap: 8px;
  height: 32px;
  justify-content: center;
  margin: 0px;
  outline: 0px;
  padding: 0 8px;
  position: relative;
  text-transform: uppercase;
  svg {
    width: 100%;
    max-width: 24px;
    flex: 0 0 16px;
  }
  :hover {
    text-decoration: none;
    background-color: rgb(14, 14, 101);
    box-shadow: rgba(24, 23, 168, 0.24) 0px 8px 16px 0px;
    color: #fff;
  }
`;

export const customButtonStyles = {
  backgroundColor: "#1817A8",
  textTransform: "capitalize",
  fontWeight: 600,
  borderRadius: "8px",
  alignItems: "center",
  paddingRight: "16px",
  paddingLeft: "16px"
};

export const customSecondaryStyles = {
  ...customButtonStyles,
  backgroundColor: "#DFE3E8",
  color: "#212B36",
  "&:hover": {
    backgroundColor: "#DFE3E8",
    color: "#000"
  }
};
export const orangeButton = {
  ...customButtonStyles,
  backgroundColor: "#FFA48D",

  color: "#fff",
  gap: "8px",
  svg: {
    width: "16px",
    height: "16px",
    path: {
      stroke: "#fff"
    }
  },
  "&:hover": {
    backgroundColor: "#FFA48D"
  }
};

export const loadingStyles = {
  position: "relative",
  cursor: "default",
  pointerEvents: "none",
  overflow: "hidden"
};

export const TitleQButtonLink = TitleQButton.withComponent("a");
