import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { debounce } from "lodash";
import { TextField } from "@material-ui/core";
import { Box, Grid, Stack, Typography, Autocomplete } from "@mui/material";
import { OtherContactImg } from "./contactCardStyles";
import ContactTypeDropdown from "./ContactTypeDropdown";
import DebouncedAutoComplete from "../../../ui/DebouncedAutoComplete";

import { ContactOptionWrap } from "./phonebookContactStyles";
import { Contact, ContactType, PropertyInfo } from "../../../Providers/types";
import validateEmail from "../../../utils/validateEmail";
export default function OtherContact({
  contact,
  setContact,
  setContactType,
  contactType
}: {
  contact: Contact;
  setContact: React.Dispatch<React.SetStateAction<Contact>>;
  setContactType: React.Dispatch<React.SetStateAction<ContactType>>;
  contactType: ContactType;
}) {
  const [otherContactType, setOtherContactType] = useState("");
  const [addressResults, setAddressResults] = useState<PropertyInfo[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [contactList, setContactList] = useState<Contact[]>([]);
  const [isValid, setIsValid] = useState(true);

  const onContactSearchTextChanged = async (text: string) => {
    if (!text || text.length < 3) {
      setContactList([]);
      setIsLoading(false);
      return;
    }
    const actualContactType = contactType.name.toLowerCase().includes("attorney") ? "attorney" : contactType.name;
    const { data } = await axios.get(
      `/api/orders/search?contactType=${actualContactType}&searchTerm=${text}&isOther=${contactType.isOther}`
    );
    setContactList(
      data.map((c) => ({
        ...c,
        emailsSplit: c.email ? c.email.split(";") : []
      }))
    );
    setIsLoading(false);
  };

  const debouncedOnContactSearchChange = useCallback(debounce(onContactSearchTextChanged, 500), []);

  const handleNameInputChange = async (event: React.SyntheticEvent, newValue: string, reason: string) => {
    if (reason === "reset") {
      return;
    }
    ///We only want to search the options if the user entered the option and not selected a the autocomplete of a partially typed option
    if (reason == "input") {
      setIsLoading(true);
      await debouncedOnContactSearchChange(newValue);
    }
  };

  const searchAddress = async (text: string) => {
    if (!text) {
      return [];
    }
    let { data } = await axios({
      method: "get",
      url: `/api/properties/search?searchText=${encodeURIComponent(text)}`
    });
    return data.suggestions.map(
      (d: any) =>
        ({
          entries: d.entries,
          aptNo: d.secondary,
          address: d.street_line,
          formattedAddress: d.formattedStreet_line,
          city: d.city,
          state: d.state,
          zipCode: d.zipcode
        }) as PropertyInfo
    );
  };

  const handleSelectTypeAheadAddress = (value: PropertyInfo) => {
    const { formattedAddress, aptNo, city, state, zipCode } = value;
    setContact({
      ...contact,
      address1: formattedAddress || "",
      address2: aptNo || "",
      city: city || "",
      state: state || "",
      zip: zipCode || ""
    });
  };

  return (
    <>
      <Grid container spacing={2} marginTop={1} marginBottom={1}>
        <Grid item xs={contactType?.name !== "Other" ? 12 : 6}>
          <Stack direction="row" justifyContent="center">
            <Box width={contactType?.name !== "Other" ? "50%" : "100%"}>
              <ContactTypeDropdown
                setContactType={setContactType}
                contactType={contactType}
                setContact={setContact}
                contact={contact}
              />
            </Box>
          </Stack>
        </Grid>
        {(contactType?.name === "Other" || otherContactType) && (
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Please describe type of other"
              label="Other Type"
              size="small"
              value={otherContactType || ""}
              onChange={(e) => {
                setOtherContactType(e.target.value);
              }}
              onBlur={(e) =>
                setContact({
                  ...contact,
                  otherContactType: e.target.value
                } as Contact)
              }
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <Stack direction="row" justifyContent="center">
            <OtherContactImg />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="column" spacing={2}>
            <Autocomplete
              fullWidth
              options={isLoading ? [] : contactList}
              filterOptions={(options) => options}
              multiple={false}
              freeSolo
              disableClearable
              inputValue={contact?.name || ""}
              onInputChange={(event, newValue, reason) => {
                setContact({ ...contact, name: newValue });
                handleNameInputChange(event, newValue, reason);
              }}
              onChange={(event, newValue) => {
                if (!newValue || typeof newValue == "string") return;
                setContact((c) => ({
                  code: c.code,
                  isMarketingSource: c.isMarketingSource,
                  ...newValue
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Name"
                  size="small"
                  inputProps={{ ...params.inputProps }}
                />
              )}
              getOptionLabel={(option: Contact) => option.name}
              loading={isLoading}
              renderOption={(props, option) => {
                return (
                  <ContactOptionWrap {...props} key={option?.lookupCode}>
                    <div className="top_row">
                      <div className="bold">{`${option?.name || ""}`}</div>
                      <div className="gray">{option?.email && `${option?.email || ""}`}</div>
                    </div>
                    <div className="bottom_row">
                      <div>
                        <div>{option?.address1}</div>
                        <div>{option?.phone}</div>
                      </div>
                      <div className="df col aife">
                        <div>{option?.company}</div>
                        {option?.company && <div className="gray italic">Company</div>}
                      </div>
                    </div>
                  </ContactOptionWrap>
                );
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Company / Payee Name"
              size="small"
              type="text"
              value={contact?.payeeName || contact?.name || ""}
              onChange={(e) => setContact({ ...contact, payeeName: e.target.value })}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Email"
              size="small"
              type="text"
              value={contact?.email || ""}
              error={!isValid}
              helperText={!isValid && "Invalid Email"}
              onFocus={() => setIsValid(true)}
              onChange={(e) => {
                setIsValid(true);
                setContact({
                  ...contact,
                  email: e.target.value,
                  emailsSplit: [e.target.value]
                });
              }}
              onBlur={(e) => {
                setIsValid(validateEmail(e.target.value));
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <DebouncedAutoComplete
            freeSolo
            fullWidth
            value={contact?.address1}
            options={addressResults}
            getOptionsLabel={(option) => option?.formattedAddress || contact?.address1 || ""}
            renderOption={(props, option, { selected }) => (
              <li
                {...props}
                key={`${option?.formattedAddress}${option?.entries}${option?.aptNo}${option?.city}${option?.state}${option?.zipCode}`}
              >
                <div style={{ width: "100%" }}>
                  <div>
                    <Typography sx={{ display: "inline-block" }} variant="subtitle2">
                      {option?.entries && option?.entries > 2
                        ? `${option?.formattedAddress} ${
                            option?.aptNo ? " " + option?.aptNo : ""
                          } (${option?.entries} more entries) ${option?.city}, ${option?.state} ${option?.zipCode}`
                        : `${option?.formattedAddress}${
                            option?.aptNo ? " " + option?.aptNo : ""
                          }, ${option?.city}, ${option?.state} ${option?.zipCode}`}
                    </Typography>
                  </div>
                </div>
              </li>
            )}
            onSelectedOptionChanged={(e, value) => {
              if (typeof value === "object" && value) {
                handleSelectTypeAheadAddress(value);
              }
            }}
            textboxLabel="Address Quick Entry"
            textboxPlaceholder="Start typing an address..."
            additionalOnChange={(text) => setContact({ ...contact, address1: text } as Contact)}
            onDebouncedTextChanged={async (text) => {
              const results = await searchAddress(text);
              setAddressResults(results);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="Address 2"
            size="small"
            value={contact?.address2 || ""}
            onChange={(e) => setContact({ ...contact, address2: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            label="City"
            size="small"
            type="text"
            fullWidth
            value={contact?.city || ""}
            onChange={(e) => setContact({ ...contact, city: e.target.value })}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            variant="outlined"
            label="State"
            size="small"
            type="text"
            value={contact?.state || ""}
            onChange={(e) => setContact({ ...contact, state: e.target.value })}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            variant="outlined"
            label="Zip"
            size="small"
            type="text"
            value={contact?.zip || ""}
            onChange={(e) => setContact({ ...contact, zip: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Phone"
            size="small"
            fullWidth
            type="text"
            value={contact?.phone || ""}
            variant="outlined"
            onChange={(e) => setContact({ ...contact, phone: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Cell"
            size="small"
            type="text"
            fullWidth
            value={contact?.cell || ""}
            variant="outlined"
            onChange={(e) => setContact({ ...contact, cell: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Fax"
            size="small"
            type="text"
            fullWidth
            value={contact?.fax || ""}
            variant="outlined"
            onChange={(e) => setContact({ ...contact, fax: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="License Number"
            size="small"
            type="text"
            fullWidth
            value={contact?.licenseNumber || ""}
            variant="outlined"
            onChange={(e) => setContact({ ...contact, licenseNumber: e.target.value })}
          />
        </Grid>
      </Grid>
    </>
  );
}
