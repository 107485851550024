import dayjs from "dayjs";
import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import { useOrder } from "../../Providers/OrderProvider";
import ContactCard from "../../ui/ContactCard";
import AddContact from "./contacts/AddContact";
import { CloseIcon, OpenInNewIcon } from "../../ui/icons";
import Sidedraw from "../../ui/sidedraw/index";
import { InfoContentWrap, InfoHeaderWrap, NoteCard } from "./actionsBarStyles";
import { TitleQButtonLink } from "../../ui/modalButtons";
import { Switch, ToggleButton } from "@mui/material";

export function NotesBar({ isOpen, close, startWithContacts, setClose = null }) {
  const [showContacts, setShowContacts] = useState(startWithContacts);
  const { order } = useOrder();
  const displayRef = useRef(null);
  const [showEmptyContacts, setShowEmptyContacts] = useState(false);

  useEffect(() => {
    setShowContacts(startWithContacts);
  }, [startWithContacts]);

  return (
    <Sidedraw isOpen={isOpen} close={close}>
      <InfoHeaderWrap>
        <div className="contacts_notes_header">{showContacts ? "Contacts" : "Notes"}</div>
        <div className="df aic gap3">
          {showContacts ? (
            <AddContactSection>
              <div onClick={() => setShowEmptyContacts(!showEmptyContacts)} className="df aic">
                <Switch
                  checked={showEmptyContacts}
                  onChange={() => setShowEmptyContacts(!showEmptyContacts)}
                  inputProps={{ "aria-label": "controlled" }}
                  size="small"
                />
                <span style={{ color: "#000" }}>Show Empty Contacts</span>
              </div>
              <AddContact />
            </AddContactSection>
          ) : (
            <TitleQButtonLink
              href={`https://titleq.mcres.com/notes/${order.orderNumber}`}
              target="_blank"
              rel="noopener noreferrer"
              className="all_notes_button"
            >
              <span>All Notes</span>
              <OpenInNewIcon />
            </TitleQButtonLink>
          )}
          <CloseIcon onClick={setClose} className="close-notes-button" />
        </div>
      </InfoHeaderWrap>
      <InfoContentWrap style={{ padding: 0 }}>
        {showContacts ? (
          <div>
            <div>
              {order.contacts
                .filter((c) => c.type && (showEmptyContacts || c.name))
                .map((contact) => {
                  return (
                    <div
                      style={{
                        borderLeft: !contact.name ? "2px solid lightblue" : ""
                      }}
                    >
                      <ContactCard
                        key={contact.id || contact.code}
                        code={contact.code}
                        lookupCode={contact.lookupCode}
                        name={contact.name}
                        type={contact.type}
                        address1={contact.address1}
                        address2={contact.address2}
                        cityStateZip={contact.cityStateZip}
                        city={contact.city}
                        state={contact.state}
                        zip={contact.zip}
                        phone={contact.phone}
                        cell={contact.cell}
                        fax={contact.fax}
                        email={contact.email}
                        notes={contact.notes}
                        isMarketingSource={contact.isMarketingSource}
                        payeeName={contact.payeeName}
                        represents={contact.represents}
                        company={contact.company}
                        licenseNumber={contact.licenseNumber}
                        buyerSellerInfo={contact.buyerSellerInfo}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          <div style={{ padding: "16px" }}>
            {order.notes.filter(n => n.includeInGeneralOrderNotes).map((note, index) => {
              return (
                <NoteCard key={index} displayOnOrderOpen={note.displayOnOrderOpen} ref={displayRef}>
                  <div className="note-text">{note.text}</div>
                  <div className="note-author">
                    - <span>{note.fullName}</span> on {dayjs(note.createdOn).format("MM/DD/YYYY")}
                  </div>
                </NoteCard>
              );
            })}
          </div>
        )}
      </InfoContentWrap>
    </Sidedraw>
  );
}

const AddContactSection = styled.div`
  margin-top: 0;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
  justify-content: center;
`;
