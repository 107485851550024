/** @jsxRuntime classic */
/** @jsx jsx */
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Root = styled.div`
  align-items: center;
  background-color: #000e48;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  height: 48px;
  justify-content: space-between;
  left: 0;
  padding: 0 8px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 50;
  .momentum-title {
    display: none;
  }
  .inner-wrap {
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .momentum-logo {
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      max-height: 40px;
      path {
        transition: all 0.15s ease;
        fill: #fff;
      }
    }
    :hover {
      svg path {
        fill: #17dffb;
      }
    }
  }
  svg {
    width: auto;
    width: 100%;
    height: 100%;
    max-height: 20px;
    path: {
      stroke: #fff;
    }
  }
  @media (min-width: 900px) {
    box-shadow: 0px 1px 2px rgba(46, 46, 54, 0.1),
      2px 1px 3px rgba(46, 46, 54, 0.05);
    flex-direction: column;
    height: 100vh;
    left: 0;
    padding: 0px 0px 24px;
    position: fixed;
    width: 72px;
    padding-top: 16px;
    .inner-wrap {
      flex-direction: column;
    }
    .order-search_wrap-syles {
      display: none;
    }
    .momentum-logo {
      width: 40px;
      height: 40px;
    }
  }
`;

export const NavWrap = styled.div`
  display: none;
  @media (min-width: 900px) {
    display: flex;
    margin-left: 20px;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    margin-top: 16px;
  }
`;

const currentBorder = css`
  ::before {
    background-color: #00b8d4;
    border-radius: 5px;
    content: '';
    height: 40px;
    left: -16px;
    position: absolute;
    width: 3px;
  }
`;

export const NavButton = styled.div`
  align-items: center;
  display: flex;
  margin-right: 20px;
  position: relative;
  span {
    display: none;
  }
  @media (min-width: 900px) {
    align-items: center;
    background-color: ${(props) => (props.current ? '#00b8d4' : '#1f2b5e')};
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    margin-right: 0;
    margin-top: 8px;
    width: 40px;
    ${(props) => props.current && currentBorder}
    img,
    svg {
      max-height: 18px;
      width: auto;
      path {
        stroke: ${(props) => props.current && '#fff'};
      }
    }
    :hover {
      svg path {
        stroke: ${(props) => (props.current ? '#fff' : '#00b8d4')};
      }
      .show-tooltip {
        display: flex;
      }
    }
  }
  @media (min-width: 900px) {
    img,
    svg {
      max-height: 20px;
    }
  }
`;

// export const RecordablesWrap = styled.a`
//   :hover {
//     svg {
//       path {
//         fill: #00b8d4;
//         /* stroke: red; */
//       }
//     }
//   }
// `;

export const RecordablesWrap = styled.a`
  align-items: center;
  display: flex;
  margin-right: 20px;
  position: relative;
  span {
    display: none;
  }
  @media (min-width: 900px) {
    align-items: center;
    background-color: ${(props) => (props.current ? '#00b8d4' : '#1f2b5e')};
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    margin-right: 0;
    margin-top: 8px;
    width: 40px;
    ${(props) => props.current && currentBorder}
    svg {
      max-height: 18px;
      width: auto;
      path {
        fill: ${(props) => props.current && '#fff'};
      }
    }
    :hover {
      svg path {
        fill: ${(props) => (props.current ? '#fff' : '#00b8d4')};
      }
      .show-tooltip {
        display: flex;
      }
    }
  }
  @media (min-width: 900px) {
    svg {
      max-height: 20px;
    }
  }
`;
