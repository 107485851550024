import { Grid, Stack, Box, Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Rep } from "../../../Providers/types";

interface IProps {
  orderNumber: string;
  salesRep1: Rep | null;
  salesRep2: Rep | null;
  salesRep3: Rep | null;
  setSalesRep1: React.Dispatch<React.SetStateAction<Rep | null>>;
  setSalesRep2: React.Dispatch<React.SetStateAction<Rep | null>>;
  setSalesRep3: React.Dispatch<React.SetStateAction<Rep | null>>;
}

function SalesRepForm({
  orderNumber,
  salesRep1,
  salesRep2,
  salesRep3,
  setSalesRep1,
  setSalesRep2,
  setSalesRep3
}: IProps) {
  const [salesPersonList, setSalesPersonList] = useState<Rep[]>([]);

  const getOrdersSalesReps = async () => {
    const { data: salesRepsOnOrder } = await axios.get(`/api/orders/getsalesreps?ordernumber=${orderNumber}`);
    const [rep1, rep2, rep3] = salesRepsOnOrder || [];
    if (rep1) setSalesRep1(rep1);
    if (rep2) setSalesRep2(rep2);
    if (rep3) setSalesRep3(rep3);
  };

  const getSalesPersonList = async () => {
    const { data } = await axios.get(`/api/Orders/GetSalesPersons`);
    const uniquesMap = {};
    //you need to make the list unique since the data returns a few doubles and then the sort will not work
    data.forEach((d: Rep) => {
      if (!uniquesMap[d.fullName]) {
        uniquesMap[d.fullName] = d;
      }
    });
    setSalesPersonList(Object.values(uniquesMap));
  };

  useEffect(() => {
    if (!orderNumber) return;
    getSalesPersonList();
    getOrdersSalesReps();
  }, [orderNumber]);

  return (
    <>
      <Grid container spacing={2} marginTop={6}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="center">
            <Box width={"50%"}>
              <Stack spacing={4}>
                {/* <TextField fullWidth variant="outlined" label="Contact Type" size="small" value={contactType?.name || ""} disabled /> */}
                <Autocomplete
                  size="small"
                  fullWidth
                  multiple={false}
                  value={salesRep1}
                  options={salesPersonList}
                  getOptionLabel={(option: Rep) => option.name}
                  onChange={(e, value: any) => {
                    setSalesRep1(value);
                  }}
                  renderInput={(params) => (
                    <TextField size="small" {...params} label="Sales Person" placeholder="Start typing..." />
                  )}
                />

                <Autocomplete
                  size="small"
                  fullWidth
                  options={salesPersonList}
                  getOptionLabel={(option: Rep) => option.name}
                  multiple={false}
                  value={salesRep2}
                  onChange={(e, value: any) => {
                    setSalesRep2(value);
                  }}
                  renderInput={(params) => (
                    <TextField size="small" {...params} label="Sales Person" placeholder="Start typing..." />
                  )}
                />

                <Autocomplete
                  size="small"
                  fullWidth
                  options={salesPersonList}
                  getOptionLabel={(option: Rep) => option.name}
                  multiple={false}
                  value={salesRep3}
                  onChange={(e, value: any) => {
                    setSalesRep3(value);
                  }}
                  renderInput={(params) => (
                    <TextField size="small" {...params} label="Sales Person" placeholder="Start typing..." />
                  )}
                />
              </Stack>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default SalesRepForm;
