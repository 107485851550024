import { useEffect, useState } from "react";

const order = {
  mtaNum: "",
  textToLink: "",
  category: "",
  subcategoryId: "",
  subcategory: "",
  description: "",
  additionalDescription: ""
};

const useDocumentDetailsFormState = (onSubmit) => {
  const [data, setData] = useState({ ...order });
  const [errors, setErrors] = useState({
    mtaNum: null,
    category: false,
    subcategoryId: false,
    description: false,
    additionalDescription: false
  });
  const [submitClicked, setSubmitClicked] = useState(false);
  const [hasButtonEverBeenClicked, setHasButtonEverBeenClicked] = useState(false);

  useEffect(() => {
    if (!hasButtonEverBeenClicked && !submitClicked) {
      return;
    }

    setHasButtonEverBeenClicked(true);

    const { mtaNum, category, subcategoryId, description, additionalDescription } = data;
    const updatedErrors = {
      mtaNum: !mtaNum,
      category: !category,
      subcategoryId: !subcategoryId,
      description: !description,
      additionalDescription: description === "Other" ? !additionalDescription : false
    };
    setErrors(updatedErrors);
    if (submitClicked && Object.keys(updatedErrors).every((key) => !updatedErrors[key])) {
      onSubmit();
    }
    setSubmitClicked(false);
  }, [submitClicked, data]);

  const rerender = () => setData({ ...order });

  return {
    data,
    setData,
    errors,
    setSubmitClicked,
    rerender
  };
};

export default useDocumentDetailsFormState;
