import { Button } from "@mui/material";
import React from "react";
import { InfoIcon } from "../../../ui/icons";

const PhonebookWarning = ({ onClick }: { onClick: React.MouseEventHandler<HTMLButtonElement> }) => {
  return (
    <>
      <div className="warning">
        <InfoIcon color="red" width="3%" height="3%" />
        <span style={{ marginLeft: "15px" }}>No Lookup Code Found!</span>
      </div>
      <div>
        The contact in the order does not have a lookup code and therefore is not associated with a phonebook contact.
        To connect the order contact with the phonebook, press the X icon in the right side of the “search for ...”
        field and choose a contact from the phonebook.
      </div>
      <div className="df jcfe mt5">
        <Button variant="contained" disableElevation color="primary" size="small" onClick={onClick}>
          Ok
        </Button>
      </div>
    </>
  );
};

export default PhonebookWarning;
