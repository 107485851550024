const moveItem = (list: Array<any>, dragIndex: number, hoverIndex: number) => {
  const dragItem = list[dragIndex];
  const newArray = [...list];
  newArray.splice(dragIndex, 1);
  newArray.splice(hoverIndex, 0, dragItem);
  return newArray;
};

const sort = (list: Array<any>, sortASC: boolean) => {
  const newList = [...list].sort(
    (a, b) => new Date(sortASC ? a.date1 : b.date1).valueOf() - new Date(sortASC ? b.date1 : a.date1).valueOf()
  );
  return newList;
};

export { moveItem, sort };
