/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { moveItem, sort } from "../../../helpers/draggableList";
import { useDocuments } from "../../../Providers/DocumentsProvider";
import { ModalType } from "../../../Providers/types";
import ActionSideDraw from "../../../ui/ActionSideDraw";
import DraggableList from "../../../ui/DraggableList";
import { XIcon } from "../../../ui/icons";
import { BaseButton, ButtonsWrap, GhostButton, RedModalButton } from "../../../ui/modalButtons";
import { InnerModal } from "../../../ui/modalStyles";
import Spinner from "../../../ui/Spinner";
import { Document } from "../types";

export default function Delete() {
  const {
    uncheckAllDocuments,
    selectedDocuments,
    getDocuments,
    toggleModal,
    closeModal,
    currentOpenModal,
    handleDocumentCheckbox
  } = useDocuments();
  const [modalDocuments, setModalDocuments] = useState<Document[]>([]);
  const action = "Delete";
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  useEffect(() => {
    setModalDocuments(selectedDocuments);
  }, [selectedDocuments]);

  const handleMoveItem = (dragIndex, hoverIndex) => {
    const newList = moveItem(modalDocuments, dragIndex, hoverIndex);
    setModalDocuments(newList);
  };

  const handleSort = (sortASC = true) => {
    const newList = sort(modalDocuments, sortASC);
    setModalDocuments(newList);
  };

  async function handleSubmit() {
    setIsSubmitting(true);
    if (!selectedDocuments.length) {
      alert("No documents have been selected");
      return;
    }
    await axios.post(
      "/api/documents/deletemultiple",
      selectedDocuments.map((x) => x.id)
    );
    uncheckAllDocuments();
    closeModal();
    await getDocuments();
    setIsConfirm(false);
    setIsSubmitting(false);
  }

  return (
    <React.Fragment>
      <div onClick={() => toggleModal(ModalType.Delete)} className="file-action-button">
        {action}
      </div>
      {currentOpenModal === ModalType.Delete && (
        <ActionSideDraw close={closeModal}>
          <InnerModalDelete isConfirm={isConfirm}>
            <div className="modal-header">
              <div className="modal-title">{action}</div>
              <div className="close-icon" onClick={closeModal}>
                <XIcon />
              </div>
            </div>
            <DraggableList
              items={modalDocuments}
              moveItem={handleMoveItem}
              handleSort={(sortASC) => handleSort(sortASC)}
              handleDelete={(item) => handleDocumentCheckbox(false, item)}
              handleDeleteAll={uncheckAllDocuments}
            />
            <ConfirmModal isConfirm={isConfirm}>
              <div className="confirm-tile">
                <div>Are you sure you want to delete the selected files?</div>
                <ButtonsWrap css={{ justifyContent: "center", padding: "0 16px", marginTop: 40 }}>
                  <GhostButton onClick={() => setIsConfirm(() => false)}>Never Mind</GhostButton>
                  <RedModalButton
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                    isDisabled={modalDocuments.length === 0}
                  >
                    <Spinner local={true} display={isSubmitting} />
                    {action}
                  </RedModalButton>
                </ButtonsWrap>
              </div>
            </ConfirmModal>
          </InnerModalDelete>
          {!isConfirm && (
            <ButtonsWrap>
              <GhostButton onClick={closeModal}>Cancel</GhostButton>
              <BaseButton
                // onClick={handleSubmit}
                onClick={() => setIsConfirm(true)}
                isLoading={isSubmitting}
                isDisabled={modalDocuments.length === 0}
              >
                <Spinner local={true} display={isSubmitting} />
                {action}
              </BaseButton>
            </ButtonsWrap>
          )}
        </ActionSideDraw>
      )}
    </React.Fragment>
  );
}

const InnerModalDelete = styled(InnerModal)<{ isConfirm?: boolean }>`
  padding: 20px;
  height: ${(props) => (props.isConfirm ? "calc(100vh - 76px)" : "calc(100vh - 116px)")};
  .list-wrap_or {
    max-height: calc(100vh - 320px);
  }
`;

const ConfirmModal = styled.div<{ isConfirm?: boolean }>`
  align-items: ${(props) => (props.isConfirm ? "flex-end" : "center")};
  background-color: rgba(31, 43, 94, 0.00843437);
  display: flex;
  height: ${(props) => (props.isConfirm ? "100%" : "0px")};
  left: 0;
  overflow: hidden;
  padding: ${(props) => props.isConfirm && " 24px 24px 40px"};
  position: absolute;
  top: 0;
  transition: all 0.3s ease-in-out;
  width: 100%;
  .confirm-tile {
    width: 100%;
    height: auto;
    background-color: #fff;
    padding: 24px;
    border-radius: 5px;
    box-shadow:
      0px 9px 112px rgba(31, 43, 94, 0.03),
      0px 3.75998px 46.7909px rgba(31, 43, 94, 0.0215656),
      0px 2.01027px 25.0167px rgba(31, 43, 94, 0.0178832),
      0px 1.12694px 14.0241px rgba(31, 43, 94, 0.015),
      0px 0.598509px 7.44811px rgba(31, 43, 94, 0.0121168),
      0px 0.249053px 3.09933px rgba(31, 43, 94, 0.00843437);
  }
`;
