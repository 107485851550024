/** @jsxRuntime classic */
/** @jsx jsx */
import { Global, jsx } from '@emotion/react';
import { Fragment } from 'react';
import { CloseIcon } from './icons';
import { Tile } from './modalStyles';

export default function ActionSideDraw({ children, close, tileStyles = null }) {
  return (
    <Fragment>
      <Global
        styles={{
          body: {
            height: '100vh',
            overflow: 'hidden',
          },
        }}
      />
      <Tile css={tileStyles}>
        <div className='close' onClick={close}>
          <CloseIcon />
        </div>
        {children}
      </Tile>
    </Fragment>
  );
}
