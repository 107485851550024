import styled from "@emotion/styled";

export const EditForm = styled.div`
  width: 100%;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 49944;
  > div {
    width: 600px;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    max-height: calc(100vh - 48px);
    overflow: auto;
  }
  .editFormHeader {
    display: flex;
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-bottom: 0.1px solid black;
    height: 40px;
    > div {
      margin-right: 8px;
      font-size: 20px;
      font-weight: 700;
    }
  }
  .inputWrap_styles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .full_width {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    /* border: 1px solid blue; */
  }
  .half_width {
    max-width: 48%;
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
    /* border: 1px solid red; */
  }

  .half_width-wrap {
    display: flex;
    justify-content: space-between;
  }

  .text-input_wrap .MuiOutlinedInput-root {
    border-radius: 10px;
  }
  .inputWrap_styles .MuiOutlinedInput-root {
    border-radius: 10px;
  }
  .toggle {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 20px;
  }

  .toggle-switch {
    display: inline-block;
    background: #ccc;
    border-radius: 16px;
    width: 58px;
    height: 32px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;
  }
  .toggle-switch:before,
  .toggle-switch:after {
    content: '';
  }
  .toggle-switch:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;

    width: 24px;
    height: 24px;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: left 0.25s;
  }
  .toggle:hover .toggle-switch:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
  }
  .toggle-checkbox:checked + .toggle-switch {
    background: rgba(17, 185, 84, 0.9);
  }
  .toggle-checkbox:checked + .toggle-switch:before {
    left: 30px;
  }

  .toggle-checkbox {
    position: absolute;
    visibility: hidden;
  }

  .toggle-label {
    margin-left: 5px;
    position: relative;
    top: 2px;
  }
  .buttonsWrap {
    margin-right: -16px;
  }
`;
export const ErrorMessageWrap = styled.div`
  background-color: #f7f7f9;
  padding: 20px;
  margin-bottom: 20px;
  border: 2px solid #ee765c;
  border-radius: 10px;
  h2 {
    color: #ee765c;
  }
`;

export const SuccessMessageWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  .wasUpdatedWrapper {
    background-color: rgba(0, 216, 166, 0.2);
    border-radius: 10px;
    color: #006950;
    display: inline-flex;
    font-weight: 700;
    margin-top: 12px;
    padding: 8px 24px;
    gap: 16px;
    vertical-align: middle;
    align-items: center;
  }
`;

export function Star({ ...props }) {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.04894 0.927052C6.3483 0.0057416 7.6517 0.00574088 7.95106 0.927052L8.79611 3.52786C8.92999 3.93989 9.31394 4.21885 9.74717 4.21885H12.4818C13.4505 4.21885 13.8533 5.45846 13.0696 6.02786L10.8572 7.63525C10.5067 7.8899 10.3601 8.34127 10.494 8.75329L11.339 11.3541C11.6384 12.2754 10.5839 13.0415 9.80017 12.4721L7.58779 10.8647C7.2373 10.6101 6.7627 10.6101 6.41222 10.8647L4.19983 12.4721C3.41612 13.0415 2.36164 12.2754 2.66099 11.3541L3.50604 8.75329C3.63992 8.34127 3.49326 7.8899 3.14277 7.63525L0.930391 6.02787C0.146677 5.45846 0.549452 4.21885 1.51818 4.21885H4.25283C4.68606 4.21885 5.07001 3.93989 5.20389 3.52786L6.04894 0.927052Z"
        fill="#FFAE00"
      />
    </svg>
  );
}
