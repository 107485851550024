import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { BaseButton, GhostButton } from "../modalButtons";

interface ModalProps {
  children?: ReactNode;
  closeClick: () => void;
  confirmText: string;
  confirmClick: () => void;
  bodyText?: string;
}

export default function Modal({ children, closeClick, confirmText, confirmClick, bodyText }: ModalProps) {
  return (
    <Root>
      <div className="inner-wrap">
        {bodyText}
        {children}
        <div className="buttons_wrap">
          <GhostButton onClick={closeClick}>Close</GhostButton>
          <BaseButton onClick={confirmClick}>{confirmText}</BaseButton>
        </div>
      </div>
    </Root>
  );
}

const Root = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  .inner-wrap {
    max-width: 500px;
    width: 80%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow:
      0px 1px 2px rgba(46, 46, 54, 0.1),
      2px 1px 3px rgba(46, 46, 54, 0.05);
    display: flex;
    flex-direction: column;
    padding: 24px;
  }
  .buttons_wrap {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 32px;
  }
`;
