import React from 'react';
import styled from '@emotion/styled';

export default function Loading() {
  return (
    <Root>
      <div className='text'>Loading</div>
      <div>
        <div className='circle bounce1'></div>
        <div className='circle bounce2'></div>
        <div className='circle bounce3'></div>
      </div>
    </Root>
  );
}

const Root = styled.div`
  text-align: center;
  display: flex;
  .text {
    margin-right: 16px;
  }
  .circle {
    width: 14px;
    height: 14px;
    background-color: #00b8d4;

    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    animation-delay: -0.32s;
  }

  .bounce2 {
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;
