import React from "react";
import styled from "@emotion/styled";
import { useDocuments } from "../../Providers/DocumentsProvider";

export default function FileDirectory() {
  const { documentsByCategory, handleScrollClick, activeTabCategory, isTabView } = useDocuments();
  return (
    <Root>
      <ul>
        {documentsByCategory
          .filter((doc) => (!isTabView ? true : doc.name === activeTabCategory))
          .map((doc, index) => {
            return (
              <div className="section_wrap" key={index}>
                {!isTabView && (
                  <li
                    key={index}
                    onClick={() => handleScrollClick(doc.subcategories[0].scrollToName)}
                    className="list-header"
                  >
                    {doc.name}
                  </li>
                )}
                <div>
                  {doc.subcategories.map((sub) => {
                    return (
                      <li
                        className="list-item_ffvdfvd"
                        key={sub.id}
                        onClick={() => handleScrollClick(sub.scrollToName)}
                      >
                        {sub.name}
                      </li>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </ul>
    </Root>
  );
}

const Root = styled.div`
  background-color: #fff;
  border: 1px solid #efefef;
  color: #1f2b5e;
  display: inline-block;
  /* height: calc(100vh - 602px); */
  flex: 1 1 auto;
  margin-top: 16px;
  overflow-y: auto;
  padding-top: 8px;
  width: 100%;
  margin-bottom: 8px;
  .section_wrap {
    padding: 8px 16px;
    /* border-top: 1px solid #ebecf1; */
    :first-of-type {
      border: none;
    }
  }
  .list-header {
    font-size: 16px;
    font-weight: bold;
    list-style-type: none;
    margin-bottom: 10px;
    margin-left: 0;
    margin-top: 0px;
  }
  .list-item_ffvdfvd {
    position: relative;
    :before {
      content: "";
      display: block;
      height: 1px;
      width: 15px;
      border-bottom: 1px solid #707898;
      position: absolute;
      left: -20px;
      bottom: 50%;
      transition: all 0.1s;
    }
    :after {
      content: "";
      display: block;
      height: 26px;
      width: 1px;
      border-right: 1px solid #707898;
      position: absolute;
      left: -20px;
      bottom: 50%;
    }
    :first-of-type {
      :after {
        height: 18px;
      }
    }
  }
  li {
    margin-top: 6px;
    margin-left: 24px;
    cursor: pointer;
    transition: all 0.1s;
    list-style-type: disc;
    list-style-type: none;
    :hover {
      color: #00b8d4;
      :before {
        border-color: #00b8d4;
      }
    }
  }
  @media (max-width: 899px) {
    display: none;
  }
  @media (min-width: 1240px) {
    /* height: calc(100vh - 600px); */
  }
`;
