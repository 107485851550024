import { useEffect } from "react";

const isMuiDropDown = (element) => {
  if (element.classList.contains("MuiAutocomplete-option")) {
    return true;
  }

  const parentLi = element.closest(".MuiAutocomplete-option");
  return !!parentLi;
};

function useOnClickOutside(ref, handler, ...additionalIgnoreRefs) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target) || isMuiDropDown(event.target)) {
        return;
      }
      if (additionalIgnoreRefs && additionalIgnoreRefs.length) {
        if (additionalIgnoreRefs.some((ref) => !ref.current || ref.current.contains(event.target))) {
          return;
        }
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

export default useOnClickOutside;
